import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import ErrorMessageRequired from "../Validation/ErrorMessageRequired";
import ErrorMessagePattern from "../Validation/ErrorMessagePattern";
import ErrorMessage from "components/Validation/ErrorMessage";

interface Props {
  register: any;
  errors: any;
  placeholder?: string;
  newLayout?: boolean;
}

const Email = ({register, errors, placeholder, newLayout}: Props) => {

  const [t] = useTranslation();

  const renderErrors = () => {
    <Fragment>
      <ErrorMessageRequired errors={errors}/>
      <ErrorMessage errors={errors}  errorType="maxLength" message={t('MAXLENGTH_VALIDATION', "50")} />
      <ErrorMessagePattern errors={errors} errorMsgKey={'VALIDATION_FIELD_EMAIL_PATTERN'} />
    </Fragment>
  }

  return (
    <>
      {newLayout && renderErrors()}
      <input
        name="email"
        id="email"
        ref={register({
            required: true,
            maxLength: 50,
            pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i
          }
        )}
        type="text"
        placeholder={`${placeholder || t('EMAIL_ADDRESS')} *`}
      />
      {newLayout && <label htmlFor="email">{`${placeholder || t('EMAIL_ADDRESS')}  *`}</label>}
      {!newLayout && renderErrors()}
    </>)
}

export default Email;