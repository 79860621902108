import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import * as helper from '../Helper';
import * as types from '../../store/db/types';
import {Typeahead} from '@gforge/react-typeahead-ts';
import * as api from '../../api';

import styles from './Registration.module.scss';

export interface SelectedRegistration {
  competitionType?: number;
  knownPartner?: types.Player;
  unknownPartner?: string;
}

enum PartnerError {
  REQUIRED, PARTNER_HAS_REGISTRATION
}

interface Props {
  competition: types.Competition;
  player: types.Player;
  competitionTypes: types.CompetitionType[];
  registration?: SelectedRegistration;
  nr: number;
  required?: boolean;
  onChange(registration?: SelectedRegistration): void;
}

const Registration = ({competition, player, competitionTypes, nr, registration, required, onChange}: Props) =>  {
  const {t} = useTranslation();

  const [competitionType, setCompetitionType] = useState<number>();
  const [competitionTypeError, setCompetitionTypeError] = useState<boolean>(false);
  const [partners, setPartners] = useState<types.Player[]>([]);
  const [initialPartner, setInitialPartner] = useState<string>();
  const [knownPartner, setKnownPartner] = useState<types.Player>();
  const [unknownPartner, setUnknownPartner] = useState<string>();
  const [partnerError, setPartnerError] = useState<PartnerError>();
  const [partnerWarning, setPartnerWarning] = useState<PartnerError>();
  const [otherPartner, setOtherPartner] = useState<string>();

  useEffect(() => {
    setCompetitionType(registration?.competitionType);
    setInitialPartner(registration?.unknownPartner ? registration?.unknownPartner : registration?.knownPartner?.name)
  }, [registration]);

  const changePartner = async (value: string) => {
    if (value.length < 3 || !competitionType) {
      setPartners([]);
      return
    }

    const partnerGender = types.getPartnerGender(competitionTypes.find(item => item.id === competitionType)?.name || 0);
    const genderQuery = (() => {
      if (partnerGender === types.PartnerGender.MAN) return '&gender=1';
      if (partnerGender === types.PartnerGender.WOMAN) return '&gender=2';
      if (partnerGender === types.PartnerGender.OTHER_GENDER) return player.gender === 1 ? '&gender=2' : '&gender=1';
      return '';
    })
    const players: types.Player[] = await api.get(`players/search/${value}?club=${competition.club_id}${genderQuery()}`);
    setPartners(players);
    setKnownPartner(undefined);
    setUnknownPartner(value);
    onChange({ competitionType, knownPartner: undefined, unknownPartner: value });
  }

  const selectPartner = async (value: any) => {
    const partner = partners.find(item => item.id === value.id);
    if (partner) {
      setUnknownPartner(undefined);
      setKnownPartner(partner);
      const registration: types.Registration = await api.get(
          `registrations/${competition.id}/player/${partner.id}/competition-type/${competitionType}`);
      if (registration) {
        if (registration.player2) {
          setUnknownPartner(partner.name);
          setKnownPartner(undefined);
          setPartnerError(PartnerError.PARTNER_HAS_REGISTRATION);
        } else if (registration.partner && registration.partner.toLowerCase() !== player.name.toLowerCase()) {
          setOtherPartner(registration.partner);
          setPartnerWarning(PartnerError.PARTNER_HAS_REGISTRATION);
        }
      }
      onChange({ competitionType, knownPartner: partner, unknownPartner: undefined });
    }
  }

  const renderCompetitionTypes = () => {
    return competitionTypes.map(item =>
        <option key={item.id || undefined} value={item.id || undefined}>
          {t(types.CompetitionTypeName[item.name]) as string}
        </option>);
  }

  const renderPartner = () => {
    if (!competitionType
        || !types.isCompetitionTypeWithPermanentPartner(competitionTypes.find(item => item.id === competitionType)?.name)) {
      return null;
    }
    return (
        <React.Fragment>
          <div className={styles.typeahead}>
            <Typeahead
                initialValue={initialPartner}
                options={ partners.map(item => ({id: item.id, name: item.name}))}
                filterOption="name"
                displayOption="name"
                inputDisplayOption="id"
                placeholder={t('SELECT_PARTNER')}
                onChange={(event) => changePartner(event.target.value)}
                onOptionSelected={(value) => selectPartner(value)}
                onBlur={() => !knownPartner && !unknownPartner ? setPartnerError(PartnerError.REQUIRED) : setPartnerError(undefined)}
            />
            <i className={cn("fa", "fa-info-circle", "tooltip", styles.info)}><div>{t('SELECT_PARTNER_INFO') as string}</div></i>
          </div>
          {helper.RenderRequiredError(partnerError === PartnerError.REQUIRED)}
          {helper.RenderCustomError(partnerError === PartnerError.PARTNER_HAS_REGISTRATION, 'PARTNER_HAS_REGISTRATION_ERROR')}
          {helper.RenderCustomWarning(partnerWarning === PartnerError.PARTNER_HAS_REGISTRATION, 'PARTNER_HAS_REGISTRATION_WARNING',
              {partner: otherPartner})}
        </React.Fragment>
    );
  }

  return (
      <div>
        <label>{t('COMPETITION_TYPE') as string} {nr}</label>
        <select
            value={competitionType}
            required={required}
            onChange={(event) => {
              if (event.target.value !== '') {
                setCompetitionType(Number(event.target.value));
                onChange({competitionType: Number(event.target.value), knownPartner, unknownPartner});
              } else {
                setCompetitionType(undefined);
                onChange(undefined);
              }
              setKnownPartner(undefined);
              setUnknownPartner(undefined);
              setCompetitionTypeError(required === true && !event.target.value);
            }}
            onBlur={() => setCompetitionTypeError(required === true && !competitionType)}
        >
          <option value="">{t('SELECT_COMPETITION_TYPE') as string}</option>
          {renderCompetitionTypes()}
        </select>
        {helper.RenderRequiredError(competitionTypeError)}
        {renderPartner()}
      </div>
  );
}

export default Registration;
