import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import * as routes from 'constants/routes';
import * as api from 'api';
import * as utils from 'utils';
import * as types from 'store/db/types';
import Breadcrumb from 'components/Breadcrumb';

import AdSense from 'components/AdSense';
import {Competition} from 'store/db/types';

const Competitions = () => {
  const [competitions, setCompetitions] = useState<types.Competition[]>();

  const {t} = useTranslation();
  const history = useHistory();

  const user = useSelector((state: any) => state.auth.user);
  const competition = useSelector((state: any) => state.db.competition);

  useEffect(() => {
    api.get(`competitions?clubId=${competition.club_id}`)
        .then(fetchedCompetitions => setCompetitions(fetchedCompetitions));
  }, [competition]);

  if (!utils.isManager(user, competition)) {
    return <p>{t('NO_AUTHORIZATION') as string}</p>
  }

  const renderRegistrationPeriod = (selectedCompetition: Competition) => {
    if (!selectedCompetition.registration_from || !selectedCompetition.registration_to) {
      return t('NO_REGISTRATION_PERIOD');
    }
    return utils.formatDate(t, selectedCompetition.registration_from) + ' / '
        + utils.formatDate(t, selectedCompetition.registration_to);
  }

  const renderCompetitionPeriod = (selectedCompetition: Competition) => {
    if (!selectedCompetition.first_match || !selectedCompetition.last_match) {
      return t('NO_COMPETITION_PERIOD');
    }
    return utils.formatDate(t, selectedCompetition.first_match) + ' / '
        + utils.formatDate(t, selectedCompetition.last_match);
  }

  const renderCompetitionStatus = (selectedCompetition: Competition) => {
    const now = moment();

    const registrationFromMoment = moment(selectedCompetition.registration_from
        ? selectedCompetition.registration_from : 0);
    const registrationUntilMoment = moment(selectedCompetition.registration_to
        ? selectedCompetition.registration_to : 0);
    if (selectedCompetition.matches_count === 0 || now.isBefore(registrationFromMoment)) {
      return t(types.CompetitionStatus.STATUS_IN_PREPARATION);
    }
    if (now.isAfter(registrationFromMoment)
        && now.isBefore(registrationUntilMoment)) {
      return t(types.CompetitionStatus.STATUS_REGISTRATION_OPEN);
    }
    if (now.isBefore(moment(selectedCompetition.first_match))) {
      return t(types.CompetitionStatus.STATUS_REGISTRATION_CLOSED);
    }
    if (now.isAfter(moment(selectedCompetition.first_match)) && now.isBefore(moment(selectedCompetition.last_match))) {
      return t(types.CompetitionStatus.STATUS_ACTIVE);
    }
    return t(types.CompetitionStatus.STATUS_DONE);
  }

  return (
      <div>
        <Breadcrumb title={'COMPETITIONS'} />
        <input
            type="button"
            value={'' + t('NEW_COMPETITION') as string}
            className="button"
            onClick={() => history.push(routes.MANAGE_EDIT_COMPETITION, {user, currentCompetition: competition})}
        />
        <div className="table">
          <table>
            <tbody>
            <tr>
              <th>{t('SPORT') as string}</th>
              <th>{t('COMPETITION') as string}</th>
              <th>{t('MANAGER') as string}</th>
              <th>{t('REGISTRATION_PERIOD') as string}</th>
              <th>{t('COMPETITION_PERIOD') as string}</th>
              <th>{t('STATUS') as string}</th>
              <th/>
            </tr>
            {competitions && competitions
                .filter(comp => utils.isManager(user, comp))
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((comp) => {
                  return (
                      <tr key={comp.id}>
                        <td>{t(comp.sport.toUpperCase()) as string}</td>
                        <td>{comp.name}</td>
                        <td>{comp.manager}</td>
                        <td>{renderRegistrationPeriod(comp)}</td>
                        <td>{renderCompetitionPeriod(comp)}</td>
                        <td>{renderCompetitionStatus(comp)}</td>
                        <td className="icons">
                          <i className="fa fa-eye tooltip"
                             onClick={() => history.push(routes.MANAGE_COMPETITION_OVERVIEW, {competition: comp, user})}>
                            <div>{t('COMPETITION_OVERVIEW') as string}</div>
                          </i>
                        </td>
                      </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
};

export default Competitions;