import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";

interface Props {
  errors: any;
  errorMsgKey: string;
}

const ErrorMessagePattern = ({errors, errorMsgKey}: Props) => {
  const [t] = useTranslation();

  return (
    <Fragment>
      {errors && errors.type === "pattern" && <p className="error">{t(errorMsgKey) as string}</p>}
    </Fragment>)
}

export default ErrorMessagePattern;
