import moment from 'moment';
import {TFunction} from 'i18next';

import * as types from 'store/db/types';
import {User} from 'store/auth/types';

export const isIncluded = (t: TFunction, match: types.Match, filter: string): boolean => {
  const homeNames = getRegistrationName(t, match, 'H');
  const awayNames = getRegistrationName(t, match, 'A');
  return homeNames?.toLocaleLowerCase().includes(filter) || awayNames?.toLocaleLowerCase().includes(filter);
};

export const getRegistrationName = (t: TFunction, match: types.Match, homeAway: 'H' | 'A'): string => {
  return match.registrations.filter(registration => registration.home_away === homeAway)
      .map(registration => registration.reserve_played
          ? `${t('RESERVE')} (${t('FOR')} ${registration.registration_name})`
          : registration.registration_name).join('/');
}

export const getName = (t: TFunction, registration: types.Registration): string => {
  const names = registration.player2 ? [registration.player1.name, registration.player2.name] : [registration.player1.name];
  return registration.partner ? `${names.join('/')}/${registration.partner} (${t('NO_ACCOUNT')})`
    : names.length > 1 ? names.join('/') : `${names[0]}`;
}

export const getNameWithLevel = (t: TFunction, sport: string, registration: types.Registration): string => {
  const names = registration.player2
    ? [`${registration.player1.name} (${getLevel(sport, registration, registration.player1)})`, `${registration.player2.name} (${getLevel(sport, registration, registration.player2)})`]
    : [`${registration.player1.name} (${getLevel(sport, registration, registration.player1)})`];
  return registration.partner ? `${names.join('/')}/${registration.partner} (${t('NO_ACCOUNT')})`
    : names.length > 1 ? names.join('/') : `${names[0]}`;
}

const getLevel = (sport: string, registration: types.Registration, player: types.Player): string => {
  if (sport === 'Padel') {
    return player.level_padel || '?';
  } else {
    if (registration.competition_type.name === 0 || registration.competition_type.name === 1) {
      return player.level_single || '?';
    }
    return player.level_single || '?';
  }
}

const getNumericLevel = (sport: string, registration: types.Registration, player: types.Player): number => {
  const level = getLevel(sport, registration, player);
  return Number.isNaN(+level) ? 99 : +level; 
}

export const orderRegistration = (t: TFunction, sport: string, reg1: types.Registration, reg2: types.Registration): number => {
  const levels1: number[] = reg1.player2
    ? [getNumericLevel(sport, reg1, reg1.player1), getNumericLevel(sport, reg1, reg1.player2)]
    : [getNumericLevel(sport, reg1, reg1.player1)];
  if (levels1.length === 1) {
    levels1.push(99);
  }
  const levels2: number[] = reg2.player2
  ? [getNumericLevel(sport, reg2, reg2.player1), getNumericLevel(sport, reg2, reg2.player2)]
  : [getNumericLevel(sport, reg2, reg2.player1)];
  if (levels2.length === 1) {
    levels2.push(99);
  }
  
  const sum = levels1.reduce((a, b) => a + b, 0) - levels2.reduce((a, b) => a + b, 0);
  if (sum !== 0) {
    return sum;
  }
  return getName(t, reg1).localeCompare(getName(t, reg2));
}

export const formatDate = (t: TFunction, date?: string | null): string => {
  if (!date) {
    return t('UNKNOWN');
  }
  return moment(date).format(t('DATE_FORMAT'))
}

export const formatDateTime = (t: TFunction, date?: string | null): string => {
  if (!date) {
    return t('UNKNOWN');
  }
  return moment(date).format(t('DATE_TIME_FORMAT'))
}

export const dateToString = (date: moment.Moment): string => {
  return date.format('YYYY-MM-DD HH:mm:ss')
}

export const sortMatches = (a: types.Match, b: types.Match): number => {
  if (moment(a.date_time || '`1970-01-01').unix() < moment(b.date_time || '`1970-01-01').unix()) return -1;
  if (moment(a.date_time || '`1970-01-01').unix() > moment(b.date_time || '`1970-01-01').unix()) return 1;
  if (a.court && b.court && a.court < b.court) return -1;
  if (a.court && b.court && a.court > b.court) return 1;
  return 0;
};

export const sortAvailable = (a: types.Available, b: types.Available): number => {
  if (a.date_time < b.date_time) return -1;
  if (a.date_time > b.date_time) return 1;
  if (a.court < b.court) return -1;
  if (a.court > b.court) return 1;
  return 0;
};

export const sortCompetitionTypes = (t: TFunction, a: types.CompetitionType, b: types.CompetitionType): number => {
  return t(types.CompetitionTypeName[a.name]).localeCompare(t(types.CompetitionTypeName[b.name]));
};

export const isManager = (user: User | null, competition: types.Competition | null): boolean => {
  if (!user || !competition) return false;
  return competition.competition_admins.find(item => item.player_id === user.player.id) !== undefined;
};
