import ErrorMessage from "components/Validation/ErrorMessage";
import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import ErrorMessageRequired from "../Validation/ErrorMessageRequired";

interface Props {
  register: any;
  errors: any;
  placeholder?: string;
  newLayout?: boolean;
}

const Name = ({register, errors, placeholder, newLayout}: Props) => {

  const [t] = useTranslation();

  const renderErrors = () => {
    <Fragment>
      <ErrorMessageRequired errors={errors} />
      <ErrorMessage errors={errors}  errorType="maxLength" message={t('MAXLENGTH_VALIDATION', "50")} />
    </Fragment>
  }

  return (
    <>
      {newLayout && renderErrors()}
      <input
        name="name"
        id="name"
        ref={register({ required: true, maxLength: 50 })}
        type="text"
        placeholder={`${placeholder || t('FULL_NAME') as string}  *`}
      />
      {newLayout && <label htmlFor="name">{`${placeholder || t('FULL_NAME') as string}  *`}</label>}
      {!newLayout && renderErrors()}
    </>
  )
}

export default Name;