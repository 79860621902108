import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';

import Breadcrumb from 'components/Breadcrumb';
import AdSense from 'components/AdSense';
import Loading from 'components/Common/Loading';

import * as api from 'api';
import * as authTypes from 'store/auth/types';
import * as routes from 'constants/routes';
import * as types from 'store/db/types';
import * as utils from 'utils';

import styles from './Overview.module.scss';

interface LocationState {
  competition: types.Competition
  user: authTypes.User;
}

const Overview = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as LocationState

  const [isLoading, setLoading] = useState<boolean>(true);
  const [competition, setCompetition] = useState<types.Competition>(locationState.competition);
  const [competitionTypes, setCompetitionTypes] = useState<types.CompetitionType[]>();
  const [courtsOverview, setCourtsOverview] = useState<types.CourtsOverview>();
  const [administrators, setAdministrators] = useState<types.Player[]>([]);
  const [registrations, setRegistrations] = useState<types.RegistrationsPerType[]>([]);
  const [groups, setGroups] = useState<types.Group[]>([]);
  const [hasRegulations, setRegulations] = useState<boolean>();
  const [matches, setMatches] = useState<types.Match[]>();

  const isCompetitionAdmin = utils.isManager(locationState.user, locationState.competition);


  useEffect(() => {
    if (isCompetitionAdmin) {
      (async () => {
        const [cmp,competitionTypes, courtsOverview, administrators, registrations, groups, regulations, matches]
          = await Promise.all([
          api.get(`competitions/${competition.id}`),
          api.get(`competitions/${competition.id}/types`),
          api.get(`courts/overview/${competition.id}`),
          api.get(`players/admin/${competition.id}`),
          api.get(`registrations/overview/${competition.id}`),
          api.get(`groups/${competition.id}`),
          api.get(`competitions/${competition.id}/regulations`),
          api.get(`matches/${competition.id}`)
        ]);
        setCompetition(cmp);
        setCompetitionTypes(competitionTypes);
        setCourtsOverview(courtsOverview);
        setAdministrators(administrators);
        setRegistrations(registrations);
        setGroups(groups);
        setRegulations(regulations && regulations.regulations);
        setMatches(matches);
        setLoading(false);
      })();
    }
  }, [competition.id, isCompetitionAdmin]);

  const editCourtSchedule = () => {
    if (getTotalMatches() > 0) {
      history.push(routes.MANAGE_COURT_SCHEDULE, {competition: competition});
    } else {
      history.push(routes.MANAGE_CREATE_COURT_SCHEDULE, {competition: competition});
    }
  };

  const registrationFrom = utils.formatDate(t, competition.registration_from);
  const registrationTo = utils.formatDate(t, competition.registration_to);

  if (!isCompetitionAdmin) {
    toast(t('COMPETITION_NOT_ALLOWED') as string, {type: toast.TYPE.ERROR});
    return null;
  }

  const renderRegistrations = () => {
    return (<div className={styles.registrations}>
      {competitionTypes?.sort((a, b) => utils.sortCompetitionTypes(t, a, b)).map((type: types.CompetitionType, idx: number) => {
        const registrationsForType = registrations.find(reg => reg.competitionTypeName === type.name);
        const total = registrationsForType ? registrationsForType.registrations : 0;
        return (<Fragment key={idx}><p>{t(types.CompetitionTypeName[type.name]) as string}</p><p>{total}</p></Fragment>);
      })}
    </div>);
  }

  const renderGroups = () => {
    return (<div className={styles.registrations}>
      {competitionTypes?.sort((a, b) => utils.sortCompetitionTypes(t, a, b)).map((type: types.CompetitionType, idx: number) => {
        const groupsType = groups.filter(group => group.competition_type.id === type.id);
        return (
          <Fragment key={idx}>
            <p>{t(types.CompetitionTypeName[type.name]) as string}</p>
            <p>{groupsType.length}</p>
          </Fragment>);
      })}
    </div>);
  }

  const getTotalMatches = () => {
    return courtsOverview ? courtsOverview.totalMatches : 0;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
      <div className={styles.Overview}>
        <Breadcrumb title={'COMPETITION_OVERVIEW'} />
        <div className={styles.container}>
          <div>
            <h4>{t('COMPETITION_DETAILS') as string}</h4>
            <i className="fa fa-pencil-alt"
                onClick={() => history.push(routes.MANAGE_EDIT_COMPETITION, {competition: competition,
                    user: locationState.user})}>
            </i>
            {t(competition.sport.toUpperCase()) as string}<br />
            {competition.name}<br/>
            {competition.manager}<br/>
            {competition.email}<br/>
            {t('REGISTRATION_PERIOD') as string}: {registrationFrom} / {registrationTo}<br/>
          </div>
          <div>
            <h4>{t('COMPETITION_TYPES') as string}</h4>
            <i className="fa fa-pencil-alt"
                onClick={() => history.push(routes.MANAGE_COMPETITION_TYPES, {competition: competition})}>
            </i>
            <ul>
                {competitionTypes?.sort((a, b)=> utils.sortCompetitionTypes(t, a, b)).map((type: types.CompetitionType, idx: number) => 
                    <li key={idx}>{t(types.CompetitionTypeName[type.name]) as string}</li>)}
            </ul>
          </div>
          <div>
            <h4>{t('COURT_SCHEDULE') as string}</h4>
            <i className="fa fa-pencil-alt"
                onClick={editCourtSchedule}>
            </i>
            {getTotalMatches() > 0 && t('COURTS_OVERVIEW_MATCHES', {
              firstDate: utils.formatDateTime(t, courtsOverview!.firstDate),
              lastDate: utils.formatDateTime(t, courtsOverview!.lastDate),
              totalMatches: courtsOverview!.totalMatches
            }) as string}
            {(getTotalMatches() === 0) && t('COURTS_OVERVIEW_NO_MATCHES') as string}
          </div>
          <div>
            <h4>{t('COMPETITION_MANAGERS') as string}</h4>
            <i className="fa fa-pencil-alt"
                onClick={() => history.push(routes.MANAGE_ADMINISTRATORS, {competition: competition})}>
            </i>
            <ul>
                {administrators?.map((admin: types.Player, idx: number) => <li key={idx}>{admin.name}</li>)}
            </ul>
          </div>
          <div>
            <h4>{t('REGISTRATIONS') as string}</h4>
            <i className="fa fa-pencil-alt"
                onClick={() => history.push(routes.MANAGE_REGISTRATIONS, {competition: competition})}>
            </i>
            {renderRegistrations()}
          </div>
          <div>
            <h4>{t('GROUPS') as string}</h4>
            <i className="fa fa-pencil-alt"
                onClick={() => history.push(routes.MANAGE_GROUPS, {competition: competition})}>
            </i>
            {renderGroups()}
          </div>
          <div>
            <h4>{t('MATCH_SCHEDULE') as string}</h4>
            {matches && matches.length > 0 && <i className="fa fa-pencil-alt"
                onClick={() => history.push(routes.MANAGE_MATCHES, {competition: competition})} />}
            {(!matches || matches.length === 0) && <i className="fa fa-pencil-alt"
                onClick={() => history.push(routes.MANAGE_CREATE_MATCH_SCHEDULE, {
                  competition, competitionTypes, groups, totalMatches: getTotalMatches()
                })} />}
            {matches && matches.length > 0 && t('MATCHES_SCHEDULED', matches.length.toString()) as string}
            {(!matches || matches.length === 0) && t('MATCHES_NOT_SCHEDULED') as string}

          </div>
          <div>
            <h4>{t('REGULATIONS') as string}</h4>
            <i className="fa fa-pencil-alt"
                onClick={() => history.push(routes.MANAGE_REGULATIONS, {competition: competition})}>
            </i>
            {t(hasRegulations ? 'REGULATIONS_AVAILABLE' : 'REGULATIONS_NOT_AVAILABLE') as string}
          </div>
          <div>
            <h4>{t('REGISTRATION_FEE') as string}</h4>
            <i className="fa fa-download"
                onClick={() => console.log('Download SEPA bestand nog niet geimplementeerd')}>
            </i>
            {t('TOTAL_REGISTRATION_FEE') as string}
          </div>
        </div>
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
};

export default Overview;