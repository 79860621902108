import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';

import * as routes from '../../../constants/routes';
import * as api from '../../../api';
import * as types from '../../../store/db/types';
import Breadcrumb from '../../../components/Breadcrumb';

import AdSense from '../../../components/AdSense';

interface LocationState {
  competition: types.Competition
}

const CompetitionTypes = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as LocationState

  const [competitionTypes, setCompetitionTypes] = useState<types.CompetitionType[]>();

  useEffect(() => {
    api.get(`competitions/${locationState.competition.id}/types`)
        .then(types => setCompetitionTypes(types));
  }, [locationState.competition.id]);

  return (
      <div>
        <Breadcrumb title={'COMPETITION_TYPES'} />
        <p dangerouslySetInnerHTML={{__html: t('COMPETITION_TYPES_TEXT')}} />
        <input
            type="button"
            value={'' + t('NEW_COMPETITION_TYPE')}
            className="button"
            onClick={() => history.push(routes.MANAGE_COMPETITION_TYPE, {competition: locationState.competition})}
        />
        <input
            type="button"
            value={'' + t('BACK')}
            className="button"
            onClick={() => history.goBack()}
        />
        <div className="table">
          <table>
            <tbody>
            <tr>
              <th>{t('TYPE') as string}</th>
              <th>{t('COUNT_TYPE') as string}</th>
              <th>{t('REGISTRATION_FEE') as string}</th>
              <th/>
            </tr>
            {competitionTypes && competitionTypes
                .sort((a, b) => a.name - b.name)
                .map((type) => {
                  return (
                      <tr key={type.id || undefined}>
                        <td>{t(types.CompetitionTypeName[type.name]) as string}</td>
                        <td>{t(types.CountType[type.count_type]) as string}</td>
                        <td>{type.registration_fee ? type.registration_fee.toLocaleString(undefined, {minimumFractionDigits: 2}) : t('NO_REGISTRATION_FEE') as string}</td>
                        <td className="icons">
                          <i className="fa fa-pencil-alt tooltip"
                             onClick={() => history.push(routes.MANAGE_COMPETITION_TYPE,
                                 {competitionType: type, competition: locationState.competition})}>
                            <div>{t('EDIT_COMPETITION_TYPE') as string}</div>
                          </i>
                        </td>
                      </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
};

export default CompetitionTypes;