import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import moment from 'moment';

import * as api from 'api';
import * as utils from 'utils';
import * as types from 'store/db/types';
import * as authTypes from 'store/auth/types';

import Breadcrumb from 'components/Breadcrumb';
import AdSense from 'components/AdSense';
import DatePicker from 'components/DatePicker';
import ErrorMessageRequired from 'components/Validation/ErrorMessageRequired';
import ErrorMessage from 'components/Validation/ErrorMessage';
import Email from 'components/Form/Email';
import Name from 'components/Form/Name';

interface LocationState {
  competition?: types.Competition;
  currentCompetition?: types.Competition;
  user: authTypes.User;
}

interface Form {
  sport: string;
  name: string;
  manager: string;
  email: string;
  registrationFrom: Date;
  registrationTo: Date;
  useAvailability: string;
  useCourt: string;
  moveMatch: 1 | 2;
}

const EditCompetition = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as LocationState

  const {handleSubmit, errors, getValues, setValue, register} = useForm({
    mode: 'onBlur',
    defaultValues: {
      sport: locationState.competition?.sport || 'Tennis',
      name: locationState.competition?.name,
      manager: locationState.competition?.manager,
      email: locationState.competition?.email,
      registrationFrom: locationState.competition?.registration_from
        ? moment(locationState.competition.registration_from).toDate() : undefined,
      registrationTo: locationState.competition?.registration_to
        ? moment(locationState.competition.registration_to).toDate(): undefined,
      useCourt: locationState.competition?.use_court ? '1' : '0',
      useAvailability: locationState.competition?.use_availability ? '1' : '0',
      moveMatch: locationState.competition?.move_match ? locationState.competition.move_match : '0'
    }
  });
 
  const onSave = async (data: Form) => {
    const competition: types.Competition = {
      id: locationState.competition?.id || 0,
      sport: data.sport,
      name: data.name,
      competition_admins: [{
        player_id: locationState.user.player.id
      }],
      club_id: locationState.currentCompetition?.club_id || 0,
      club_name: locationState.currentCompetition?.club_name || '',
      email: data.email,
      manager: data.manager,
      use_court: data.useCourt === '1',
      registration_from: utils.dateToString(moment(data.registrationFrom).startOf('day')),
      registration_to: utils.dateToString(moment(data.registrationTo).endOf('day')),
      use_availability: data.useAvailability === '1',
      move_match: data.moveMatch
    };

    try {
      if (locationState.competition) {
        await api.put(`competitions/${locationState.competition.id}`, competition);
      } else {
        await api.post('competitions', competition);
      }
      toast(t('SAVE_SUCCESS') as string, {type: toast.TYPE.SUCCESS});
      history.goBack();
    } catch (e) {
      toast(t('SAVE_ERROR') as string, {type: toast.TYPE.ERROR});
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  const validateRegistrationTo = (value: string) => {
    return moment(value).unix() >= moment(getValues('registrationFrom')).unix();
  };

  return (
      <div>
        <Breadcrumb title={'COMPETITIONS'} />
        <div className="content">
          <p dangerouslySetInnerHTML={{__html: t('EDIT_COMPETITION_TEXT') as string}} />
          <form onSubmit={handleSubmit(onSave)}>
          <label>{t('SPORT') as string}</label>
            <select
                // defaultValue={locationState.competition?.sport || 'Tennis'}
                name="sport"
                ref={register({required: true})}
                // onChange={(event) => setValue('sport', event.target.value)}
            >
              <option value="Padel">{t('PADEL') as string}</option>
              <option value="Tennis">{t('TENNIS') as string}</option>
            </select>
            <ErrorMessageRequired errors={errors.sport} />
            <Name register={register} errors={errors.name} placeholder={t('COMPETITION_NAME') as string} />
            <input name="manager" ref={register({required: true, maxLength: 100})} placeholder={`${t('COMPETITION_MANAGER') as string} *`} />
            <ErrorMessageRequired errors={errors.manager} />
            <ErrorMessage errors={errors.manager}  errorType="maxLength" message={t('VALIDATION_FIELD_MAXLENGTH', {length: "100"}) as string} />
            <Email register={register} errors={errors.email} placeholder={t('MANAGER_EMAIL_ADDRESS') as string} />
            <label>{t('REGISTRATION_FROM') as string}</label>
            <DatePicker
              ref={register('registrationFrom', {required: true})}
              placeholder=""
              style={{display: "block"}}
              value={getValues('registrationFrom')}
              onChange={value => setValue('registrationFrom', value)}
            />
            <ErrorMessageRequired errors={errors.registrationFrom} />
            <label>{t('REGISTRATION_TO') as string}</label>
            <DatePicker
              ref={register('registrationTo', {required: true, validate: value => validateRegistrationTo(value)})}
              placeholder=""
              style={{display: "block"}}
              value={getValues('registrationTo')}
              onChange={value => setValue('registrationTo', value)}
            />
            <ErrorMessageRequired errors={errors.registrationTo} />
            <ErrorMessage errors={errors.registrationTo} errorType="validate" message={t('VALIDATION_FIELD_AFTER_DATE_FROM') as string} />
            <label>{t('USE_AVAILABILITY') as string}</label>
            <select
              name="useAvailability"
              ref={register({required: true})}
            >
              <option value="0">{t('NO') as string}</option>
              <option value="1">{t('YES') as string}</option>
            </select>
            <ErrorMessageRequired errors={errors.useAvailability} />
            <label>{t('USE_COURT') as string}</label>
            <select
              name="useCourt"
              ref={register({required: true})}
            >
              <option value="0">{t('NO') as string}</option>
              <option value="1">{t('YES') as string}</option>
            </select>
            <ErrorMessageRequired errors={errors.useCourt} />
            <label>{t('CAN_MOVE_MATCH') as string}</label>
            <select
                name="moveMatch"
                ref={register({required: true})}
            >
              <option value="0">{t('NOBODY') as string}</option>
              <option value="1">{t('MANAGER_ONLY') as string}</option>
              <option value="2">{t('MANAGER_PLAYER') as string}</option>
            </select>
            <ErrorMessageRequired errors={errors.moveMatch} />
            <input type="submit" value={'' + t('SAVE') as string} />
            <input type="button" className="second" value={'' + t('CANCEL') as string} onClick={onCancel} />
          </form>
        </div>
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
};

export default EditCompetition;