import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import { post } from '../../api';
import * as authActions from 'store/auth/actions';
import * as dbActions from 'store/db/actions';

interface Props extends WithTranslation {
  onClick(): void;
}

function SignOutButton(props: Props) {
  const dispatch = useDispatch();

  const signOut = ({onClick}: Props, event: any) => {
    post('auth/logout', {});
    
    dispatch(authActions.storeUser(null));
    dispatch(dbActions.storePlayer(null));


    onClick();
    event.preventDefault();
  };

  return (
    <a href="not-valid" onClick={(event) => signOut(props, event)}>
      {props.t('LOGOUT') as string}
    </a>
  );
}

export default withTranslation()(SignOutButton);