import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import * as api from 'api';
import * as types from 'store/db/types';

import ErrorMessageRequired from "components/Validation/ErrorMessageRequired";

interface Props {
  register: any;
  errors: any;
}

const Club = ({register, errors}: Props) => {

  const [t] = useTranslation();

  const [clubs, setClubs] = useState<types.Club[]>([]);

  useEffect(() => {
    (async () => {
      setClubs(await api.get('clubs'));
    })();
  }, []);

  return (
    <Fragment>
      <select
          name="club"
          ref={register({required: true})}>
        {clubs.sort((a, b) => a.name.localeCompare(b.name)).map(club => <option key={club.id} value={club.id}>{club.name}</option>)}
      </select>
      <ErrorMessageRequired errors={errors} />
    </Fragment>)
}

export default Club;