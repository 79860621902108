import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';
import {isEmpty} from 'lodash';

import * as routes from 'constants/routes';
import * as api from 'api';
import * as utils from 'utils';
import * as types from 'store/db/types';
import Breadcrumb from 'components/Breadcrumb';

import AdSense from 'components/AdSense';

const Players = () => {
  const [players, setPlayers] = useState<types.Player[]>();
  const [filter, setFilter] = useState<string>();

  const {t} = useTranslation();
  const history = useHistory();

  const user = useSelector((state: any) => state.auth.user);
  const competition = useSelector((state: any) => state.db.competition);

  useEffect(() => {
    (async () => {
      setPlayers(await api.get(`players/club/${competition.club_id}`));
    })();
  }, [competition]);

  if (!utils.isManager(user, competition)) {
    return <p>{t('NO_AUTHORIZATION') as string}</p>
  }

  const constructCompetitions = (competitions: string[] | undefined) => {
    if (!competition) {
      return '';
    }

    return competitions?.map(cmp => <div key={cmp}>{cmp}</div>);
  }

  return (
      <div>
        <Breadcrumb title={'PLAYERS'} />
        {/**<input
            type="button"
            value={'' + t('NEW_PLAYER') as string}
            className="button"
            onClick={() => history.push(routes.MANAGE_PLAYER, {user, competition})}
        />*/}
        <div className={filter}>
          <input
            type="search"
            placeholder={t('SEARCH_PLAYER') as string}
            onChange={(event) => setFilter(event.target.value)} />
        </div>
        <div className="table">
          <table>
            <tbody>
            <tr>
              <th>{t('NAME') as string}</th>
              <th>{t('EMAIL_ADDRESS') as string}</th>
              <th>{t('MOBILE_NUMBER') as string}</th>
              <th>{t('GENDER') as string}</th>
              <th>{t('PLAYER_NUMBER') as string}</th>
              <th>{t('USERNAME') as string}</th>
              <th>{t('COMPETITIONS') as string}</th>
              <th/>
            </tr>
            {players && players
                .filter(player => isEmpty(filter) || player.name.toUpperCase().indexOf(filter!.toUpperCase()) > -1)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((player) => {
                  return (
                      <tr key={player.id}>
                        <td>{player.name}</td>
                        <td>{player.email}</td>
                        <td>{player.mobile}</td>
                        <td>{player.gender === 1 ? t('MALE') as string : t('FEMALE') as string}</td>
                        <td>{player.number}</td>
                        <td>{player.username}</td>
                        <td>{constructCompetitions(player.competitions)}</td>
                        <td className="icons">
                          <i className="fa fa-pencil-alt tooltip"
                             onClick={() => history.push(routes.MANAGE_PLAYER, {user, competition, player})}>
                            <div>{t('EDIT_PLAYER') as string}</div>
                          </i>
                        </td>
                      </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
};

export default Players;