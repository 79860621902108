import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import moment from 'moment';

import AdSense from 'components/AdSense';
import Breadcrumb from 'components/Breadcrumb';
import DatePicker from 'components/DatePicker';
import CourtsScheduleList from 'components/Competition/CourtsScheduleList';
import AddCourts from 'components/Competition/AddCourts';

import * as types from 'store/db/types';
import * as api from 'api';

interface LocationState {
  competition: types.Competition;
}

const CourtsSchedule = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as LocationState;

  const [showAddCourts, setShowAddCourts] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [matches, setMatches] = useState<types.Match[]>([]);

  useEffect(() => {
    (async () => {
      if (locationState) {
        setMatches(await api.get(`courts/schedule/${locationState.competition.id}`));
      }
    })();
  }, [locationState]);

  const deleteMatch = (id: number) => {
    setMatches(matches.filter(match => match.id !== id));
  };

  const saveCourt = async (dateTime: string, courts: string[]) => {
    try {
      await Promise.all(courts.filter(court => court).map(court => api.post(`matches/${locationState.competition.id}`, {
        date_time: dateTime,
        court
      })));
      setShowAddCourts(false);
      setMatches(await api.get(`courts/schedule/${locationState.competition.id}`));

      toast(t('SAVE_SUCCESS') as string, {type: toast.TYPE.SUCCESS});
    } catch (e) {
      toast(t('SAVE_ERROR') as string, {type: toast.TYPE.ERROR});
    }
  };

  const renderMatches = () => {
    let useMatches = matches;
    if (selectedDay) {
      useMatches = useMatches.filter(match => match.date_time && match.date_time.substr(0, 10) === selectedDay)
    }
    if (useMatches.length === 0) {
      return null;
    }
    return <CourtsScheduleList
        competition={locationState.competition}
        matches={useMatches}
        onDelete={deleteMatch}
    />;
  };

  const renderPopups = () => {
    if (!showAddCourts) {
      return null;
    }
    if (showAddCourts) {
      return <AddCourts
        competition={locationState.competition}
        matches={matches}
        onClose={() => setShowAddCourts(false)}
        onSave={saveCourt}
      />;
    }
    return null;
  };

  return (
      <div>
        <div>
          <Breadcrumb competition={locationState?.competition} title={'MANAGE_COURT_SCHEDULE_TITLE'} />
          <p dangerouslySetInnerHTML={{__html: t('MANAGE_COURT_SCHEDULE_TEXT') as string}} />
          <input
            type="button"
            value={'' + t('ADD_DATE_TIME_COURTS') as string}
            className="button"
            onClick={() => setShowAddCourts(true)}
        />
        <input
            type="button"
            value={'' + t('BACK') as string}
            className="button"
            onClick={() => history.goBack()}
        />
          <div className="filter">
            <DatePicker
                placeholder={t('FILTER_ON_DATE') as string}
                onChange={(date) => date ? setSelectedDay(moment(date).format('YYYY-MM-DD')) : setSelectedDay(null)}
            />
          </div>
          {renderMatches()}
        </div>
        {renderPopups()}
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
}

export default CourtsSchedule;