import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm, Controller} from 'react-hook-form';
import {Typeahead} from '@gforge/react-typeahead-ts';

import * as api from 'api';
import * as types from 'store/db/types';

import ErrorMessageRequired from 'components/Validation/ErrorMessageRequired';

interface Props {
  competition: types.Competition;
  competitionType: types.CompetitionType;
  registration?: types.Registration;
  onSave(result: types.Registration): void;
  onClose(): void;
}

interface Form {
  player1: types.Player;
  player2: types.Player | string;
}

const EditRegistration = ({competition, competitionType, registration, onSave, onClose}: Props) => {
  const {t} = useTranslation();
  const {control, handleSubmit, errors, setValue} = useForm();

  const [players, setPlayers] = useState<types.Player[]>([]);

  useEffect(() => {
    (async () => {
      if (players.length === 0) {
        setPlayers(await api.get(`players/club/${competition.club_id}`));
      }
      if (registration) {
        setValue('player1', registration.player1);
        if (registration.player2) {
          setValue('player2', registration.player2);
        } else {
          setValue('player2', registration.partner);
        }
      }
    })();
  }, [competition, players, registration, setValue]);

  const onSaveRegistration = async (data: Form) => {
    const players: types.Player[] = [data.player1];
    if (typeof data.player2 !== 'string') players.push(data.player2 as types.Player); 
    const saveRegistration: types.Registration = {
      competition_type: competitionType,
      group: registration ? registration.group : undefined,
      id: registration ? registration.id : undefined,
      partner: typeof data.player2 === 'string' ? data.player2 as string : undefined,
      player1: data.player1,
      player2: typeof data.player2 !== 'string' ? data.player2 : undefined,
    };
    if (!registration) {
      const savedRegistration: types.Registration = await api.add(t, `registrations/${competition.id}`, saveRegistration);
      if (savedRegistration !== null) {
        onSave(savedRegistration);
      }
    } else {
      if (await api.update(t, 'registrations', saveRegistration)) {
        onSave(saveRegistration);
      }
    }
  };

  const playerLength = registration && registration.player2 ? 2 : registration && registration.player1 ? 1 : 0;

  return (
      <div className="popup">
        <div className="header">{registration ? t('EDIT_REGISTRATION_TITLE') as string : t('ADD_REGISTRATION_TITLE') as string}</div>
        <div className="content">
          <p dangerouslySetInnerHTML={{__html: registration ? t('EDIT_REGISTRATION_TEXT') as string : t('ADD_REGISTRATION_TEXT') as string}} />
          <form onSubmit={handleSubmit(onSaveRegistration)}>
            <Controller
              control={control}
              name="player1"
              rules={{required: true}}
              defaultValue={registration?.player1 || null}
              render={({onChange, value}) => (
                    <Typeahead
                      placeholder={t('SEARCH_PLAYER') as string}
                      options={players.map(item => ({id: item.id, name: item.name}))}
                      filterOption="name"
                      displayOption="name"
                      inputDisplayOption="id"
                      initialValue={value ? value.name : undefined}
                      onOptionSelected={onChange}
                    />
                )}
            />
            <ErrorMessageRequired errors={errors.player1} />
            {
              types.isCompetitionTypeWithPermanentPartner(competitionType.name) &&
              <Fragment>
                <Controller
                control={control}
                name="player2"
                rules={{required: true}}
                defaultValue={playerLength > 1 ? registration!.player2 : registration?.partner || null}
                render={({onChange, value}) => (
                      <Typeahead
                        placeholder={t('SEARCH_PLAYER') as string}
                        options={players.map(item => ({id: item.id, name: item.name}))}
                        filterOption="name"
                        displayOption="name"
                        inputDisplayOption="id"
                        initialValue={playerLength > 1 ? registration!.player2?.name : registration?.partner || undefined}
                        onChange={onChange}
                        onOptionSelected={onChange}
                      />
                  )}
              />
              <ErrorMessageRequired errors={errors.player2} />
                </Fragment>
            
            }
            <input type="submit" value={'' + t('SAVE') as string} />
            <input type="button" value={'' + t('CANCEL') as string} className="lastButton second" onClick={onClose} />
          </form>
        </div>
      </div>
  );
}

export default EditRegistration;