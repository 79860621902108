import React, {useEffect, useState} from 'react';

export interface Option {
  key: any;
  value: string;
}

interface Props {
  placeholder: string;
  allItemsText?: string;
  canSelectAll?: boolean;
  defaultValue?: any;
  options: Option[];
  onChange(key: any): void;
}

const Select = ({placeholder, allItemsText, canSelectAll, defaultValue, options, onChange}: Props) => {

  const sortedOptions = options.sort((a, b) => a.value.localeCompare(b.value));

  const [selected, setSelected] = useState<number>();

  useEffect(() => {
    if (defaultValue) {
      const idx = sortedOptions.findIndex(item => item.value === defaultValue);
      if (idx >= 0) {
        setSelected(idx);
      }
    }
  }, [defaultValue, sortedOptions]);

  const onChangeSelect = (event: any) => {
    const idx = event.target.value;
    setSelected(idx);
    onChange(sortedOptions[idx].key);
  };

  const renderOptions = () => {
    return sortedOptions.map((item, idx) => {
      return <option value={idx} key={idx}>{item.value}</option>
    });
  };

  return (
    <select 
        value={selected}
        onChange={onChangeSelect}>
      {!canSelectAll && <option value="none" disabled>{placeholder}</option>}
      {canSelectAll && <option value="none" >{allItemsText}</option>}
      {renderOptions()}
    </select>
  );
};

export default Select;