import React from 'react';
import {useTranslation} from 'react-i18next';
import {useForm, Controller} from 'react-hook-form';
import {Editor} from '@tinymce/tinymce-react';

import ErrorMessageRequired from "../Validation/ErrorMessageRequired";

interface Props {
  onSendMail(subject: string, content: string): void;
  onClose(): void;
}

interface Form {
  subject: string;
  content: string;
}

const SendMailPopup = ({onSendMail, onClose}: Props) => {

  const {t} = useTranslation();
  const {control, handleSubmit, errors, register, setValue} = useForm<Form>({mode: "onBlur"});

  const onSubmit = (data: Form) => {
    onSendMail(data.subject, data.content);
  };

  const renderTinyMce = (value: string) => {
    return <Editor
      tinymceScriptSrc={process.env.PUBLIC_URL + '/js/tinymce/tinymce.min.js'}
      init={{
        height: 500,
        menubar: false,
        plugins: 'code link lists table',
        toolbar2: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | link | code'
      }}
      value={value}
      onEditorChange={(value) => setValue('content', value)}
    />
  };

  return (
      <div className="popup">
        <div className="header">{t('SEND_MAIL_POPUP_TITLE') as string}</div>
        <div className="content">
          <p dangerouslySetInnerHTML={{__html: t('SEND_MAIL_POPUP_TEXT') as string}} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="field">
              <ErrorMessageRequired errors={errors.subject}/>
              <input name="subject" id="subject" ref={register({required: true})} placeholder={`${t('SUBJECT') as string}`} />
              <label htmlFor="subject">{`${t('SUBJECT') as string}  *`}</label>
            </div>
            <div className="field nomax">
              <ErrorMessageRequired errors={errors.content}/>
              <Controller
                control={control}
                name="content"
                rules={{required: true}}
                render={
                  ({value, onChange}) => renderTinyMce(value || t('SEND_MAIL_CONTENT') as string)
                }
              />
            </div>
            <input type="submit" value={`${t('SEND_MAIL') as string}`} className="button" />
            <input type="button" value={`${t('CANCEL') as string}`} className="lastButton second" onClick={onClose} />
          </form>
        </div>
      </div>
  );
}

export default SendMailPopup;