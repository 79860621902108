import React, {Fragment, useState, MouseEvent as ReactMouseEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';

import * as api from '../../../api';
import * as types from '../../../store/db/types';
import * as helper from '../../../components/Helper';
import Breadcrumb from '../../../components/Breadcrumb';

import AdSense from '../../../components/AdSense';
import {toast} from 'react-toastify';

import styles from './CreateCourtSchedule.module.scss';
import moment from 'moment';
import DatePicker from '../../../components/DatePicker';

interface LocationState {
  competition: types.Competition;
}

interface DayTime {
  time: string;
  courts: string[];
}
interface DayTimes {
  day: string;
  times: DayTime[];
}
interface NonPlayingDate {
  date: Date | null;
}
interface Schedule {
  dateFrom: Date | null;
  dateUntil: Date | null;
  playOnMonday: number;
  playOnTuesday: number;
  playOnWednesday: number;
  playOnThursday: number;
  playOnFriday: number;
  playOnSaturday: number;
  playOnSunday: number;
  dayTimes: DayTimes[];
  nonPlayingDates: NonPlayingDate[];
}

interface Errors {
  dateFromError: boolean;
  dateUntilError: boolean;
  playOnDayError: boolean;
  dayTimeError: boolean;
}

const CreateCourtSchedule = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as LocationState
  const MONDAY = 'monday', TUESDAY = 'tuesday', WEDNESDAY = 'wednesday', THURSDAY = 'thursday', FRIDAY = 'friday';
  const SATURDAY = 'saturday', SUNDAY = 'sunday';

  const getDayTime = (day: string): DayTimes => {
    return {
      day,
      times: [{
        time: '',
        courts: ['']
      }]
    }
  }

  const [schedule, setSchedule] = useState<Schedule>({
    dateFrom: null, dateUntil: null,
    playOnMonday: 0, playOnTuesday: 0, playOnWednesday: 0, playOnThursday: 0, playOnFriday: 0,
    playOnSaturday: 0, playOnSunday: 0,
    dayTimes: [getDayTime(MONDAY), getDayTime(TUESDAY), getDayTime(WEDNESDAY), getDayTime(THURSDAY), getDayTime(FRIDAY),
      getDayTime(SATURDAY),getDayTime(SUNDAY)], nonPlayingDates: [{date: null}]
  });
  const [errors, setErrors] = useState<Errors>({
    dateFromError: false,
    dateUntilError: false,
    playOnDayError: false,
    dayTimeError: false
  });

  const onSave = async (e: ReactMouseEvent<HTMLInputElement, MouseEvent>) => {
    setErrors({dateFromError: false, dateUntilError: false, dayTimeError: false, playOnDayError: false})
    // Date from must be set an in the future
    const registrationUntil = locationState.competition.registration_to || 0;
    if (!schedule.dateFrom || moment(schedule.dateFrom).isBefore(moment(registrationUntil))) {
      setErrors({dateFromError: true, dateUntilError: false, dayTimeError: false, playOnDayError: false})
      return;
    }
    // Date To must be set and later than date from
    if (!schedule.dateUntil || moment(schedule.dateUntil).isBefore(schedule.dateFrom)) {
      setErrors({dateFromError: false, dateUntilError: true, dayTimeError: false, playOnDayError: false})
      return;
    }
    // At least one day must be selected
    if (!schedule.playOnMonday && !schedule.playOnTuesday && !schedule.playOnWednesday && !schedule.playOnThursday
        && !schedule.playOnFriday && !schedule.playOnSaturday && !schedule.playOnSunday) {
      setErrors({dateFromError: false, dateUntilError: false, dayTimeError: false, playOnDayError: true})
      return;
    }
    // For selected days at least one time must be set and for given times at least one court must be set
    if ((schedule.playOnMonday && !dayHasTimeAndCourt(MONDAY))
        || (schedule.playOnTuesday && !dayHasTimeAndCourt(TUESDAY))
        || (schedule.playOnWednesday && !dayHasTimeAndCourt(WEDNESDAY))
        || (schedule.playOnThursday && !dayHasTimeAndCourt(THURSDAY))
        || (schedule.playOnFriday && !dayHasTimeAndCourt(FRIDAY))
        || (schedule.playOnSaturday && !dayHasTimeAndCourt(SATURDAY))
        || (schedule.playOnSunday && !dayHasTimeAndCourt(SUNDAY))
    ) {
      setErrors({dateFromError: false, dateUntilError: false, dayTimeError: true, playOnDayError: false})
      return;
    }

    try {
      await api.post(`courts/schedule/${locationState.competition.id}`, schedule);
      toast(t('SAVE_SUCCESS') as string, {type: toast.TYPE.SUCCESS});
    } catch (e) {
      toast(t('SAVE_ERROR') as string, {type: toast.TYPE.ERROR});
    }

    history.goBack();
    e.preventDefault();
  };

  const dayHasTimeAndCourt = (day: string): boolean => {
    const dayTimes = schedule.dayTimes.find(d => d.day === day)
    if (!dayTimes) {
      return false;
    }
    for (const dayTime of dayTimes.times) {
      if (dayTime.time) {
        for (const court of dayTime.courts) {
          if (court) {
            return true;
          }
        }
      }
    }
    return false;
  }

  const onCancel = (e: ReactMouseEvent<HTMLInputElement, MouseEvent>) => {
    history.goBack();
    e.preventDefault();
  };

  const updateTime = (day: string, timeIdx: number, value: string, isLastElement: boolean) => {
    const dayTimes = schedule.dayTimes;
    const dayTime = dayTimes.find(d => d.day === day);
    if (dayTime) {
      dayTime.times[timeIdx] = {courts: dayTime.times[timeIdx].courts, time: value};
      if (isLastElement) {
        dayTime.times.push({courts: [''], time: ''});
      }
      setSchedule({...schedule, dayTimes});
    }
  }

  const updateCourt = (day: string, timeIdx: number, courtIndex: number, value: string, isLastElement: boolean) => {
    const dayTimes = schedule.dayTimes;
    const dayTime = dayTimes.find(d => d.day === day);
    if (dayTime) {
      dayTime.times[timeIdx].courts[courtIndex] = value;
      if (isLastElement) {
        dayTime.times[timeIdx].courts.push('');
      }
      setSchedule({...schedule, dayTimes})
    }
  }

  const updateNonPlayingDate = (idx: number, value: Date | null, isLastElement: boolean) => {
    const nonPlayingDates = schedule.nonPlayingDates;
    nonPlayingDates[idx] = {date: value};
    if (isLastElement) {
      nonPlayingDates.push({date: null});
    }
    setSchedule({...schedule, nonPlayingDates})
  }

  const renderDayTimes = () => {
    return (<Fragment>
      {(!schedule.playOnMonday && !schedule.playOnTuesday && !schedule.playOnWednesday && !schedule.playOnThursday
          && !schedule.playOnFriday && !schedule.playOnSaturday && !schedule.playOnSunday) || <div><hr/>{t('TIMES_PER_DAY') as string}<br/></div>}
      {helper.RenderCustomError(errors.dayTimeError, 'ERROR_MINIMAL_ONE_REQUIRED')}
      {!schedule.playOnMonday || renderDayTime(MONDAY)}
      {!schedule.playOnTuesday || renderDayTime(TUESDAY)}
      {!schedule.playOnWednesday || renderDayTime(WEDNESDAY)}
      {!schedule.playOnThursday || renderDayTime(THURSDAY)}
      {!schedule.playOnFriday || renderDayTime(FRIDAY)}
      {!schedule.playOnSaturday || renderDayTime(SATURDAY)}
      {!schedule.playOnSunday || renderDayTime(SUNDAY)}
    </Fragment>);
  }

  const renderDayTime = (day: string) => {
    const timeLen = schedule.dayTimes.find(d => d.day === day)?.times.length
    return (
        <Fragment>
          <div className={styles.dayTimes}>{t(day.toUpperCase()) as string}</div>Banen<br/> {schedule.dayTimes.find(d => d.day === day)?.times
            .map((t, ti) => {
              const courtLen = t.courts.length;
              return (
                  <Fragment key={day + ti}>
                    <input type="time" onChange={e => updateTime(day, ti, e.target.value, (timeLen === ti + 1))}/>
                    {t.courts.map((c, ci) => <input key={day+ti+ci} className={styles.court} type="text"
                                                    onChange={e => updateCourt(day, ti, ci, e.target.value, courtLen === ci + 1)}/>)}
                    <br/>
                  </Fragment>
              );
            })}
        </Fragment>
    )
  }

  return (
      <div className={styles.CreateCourtSchedule}>
        <Breadcrumb title={'COURT_SCHEDULE'} />
        <p dangerouslySetInnerHTML={{__html: t('COURT_SCHEDULE_CREATE_TEXT') as string}} />
        <form>
          <label>{t('COMPETITION_PERIOD') as string}</label><br/>
          <label>
            <label className={styles.date}>{t('DATE_FROM') as string}</label>
            <DatePicker
                placeholder=""
                onChange={(date) => setSchedule({...schedule, dateFrom: date})}
            />
          </label>
          {helper.RenderCustomError(errors.dateFromError, 'ERROR_REQUIRED_AFTER_REGISTRATION_PERIOD')}
          <br/>
          <label>
            <label className={styles.date}>{t('DATE_UNTIL') as string}</label>
            <DatePicker
                placeholder=""
                onChange={(date) => setSchedule({...schedule, dateUntil: date})}
            />
          </label>
          {helper.RenderCustomError(errors.dateUntilError, 'ERROR_REQUIRED_AFTER_DATE_FROM')}
          <hr/>
          <label>{t('PLAYING_DAYS') as string}</label><br/>
          <div className={styles.days}>
          <label>
            {t('MONDAY_SHORT') as string}
            <input
                type="checkbox"
                value={schedule.playOnMonday}
                required
                onChange={(event) => setSchedule({...schedule, playOnMonday: event.target.checked ? 1 : 0})}
            />
          </label>
          <label>
            {t('TUESDAY_SHORT') as string}
            <input
                type="checkbox"
                value={schedule.playOnTuesday}
                required
                onChange={(event) => setSchedule({...schedule, playOnTuesday: event.target.checked ? 1 : 0})}
            />
          </label>
          <label>
            {t('WEDNESDAY_SHORT') as string}
            <input
                type="checkbox"
                value={schedule.playOnWednesday}
                required
                onChange={(event) => setSchedule({...schedule, playOnWednesday: event.target.checked ? 1 : 0})}
            />
          </label>
          <label>
            {t('THURSDAY_SHORT') as string}
            <input
                type="checkbox"
                value={schedule.playOnThursday}
                required
                onChange={(event) => setSchedule({...schedule, playOnThursday: event.target.checked ? 1 : 0})}
            />
          </label>
          <label>
            {t('FRIDAY_SHORT') as string}
            <input
                type="checkbox"
                value={schedule.playOnFriday}
                required
                onChange={(event) => setSchedule({...schedule, playOnFriday: event.target.checked ? 1 : 0})}
            />
          </label>
          <label>
            {t('SATURDAY_SHORT') as string}
            <input
                type="checkbox"
                value={schedule.playOnSaturday}
                required
                onChange={(event) => setSchedule({...schedule, playOnSaturday: event.target.checked ? 1 : 0})}
            />
          </label>
          <label>
            {t('SUNDAY_SHORT') as string}
            <input
                type="checkbox"
                value={schedule.playOnSunday}
                required
                onChange={(event) => setSchedule({...schedule, playOnSunday: event.target.checked ? 1 : 0})}
            />
          </label></div>
          {helper.RenderCustomError(errors.playOnDayError, 'ERROR_MINIMAL_ONE_REQUIRED')}
          {renderDayTimes()}
          <hr/>
          <label>{t('NON_PLAYING_DATES') as string}</label><br/>
          {schedule.nonPlayingDates.map((d, i) => {
            const datesCount = schedule.nonPlayingDates.length;
            return (
                <Fragment key={i}>
                  <DatePicker
                      placeholder=""
                      onChange={(date) => updateNonPlayingDate(i, date, datesCount === i + 1)}
                  />
                  <br/>
                </Fragment>
            );
          })}
          <br/>
          <input
              type="button"
              value={'' + t('GENERATE_COURT_SCHEMA') as string}
              onClick={onSave}
          />
          <input
              type="button"
              value={'' + t('CANCEL') as string}
              onClick={onCancel}
          />
        </form>
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
};

export default CreateCourtSchedule;