import React, {Component, Fragment} from 'react';
import {DbReducer} from '../../store/db/reducer';
import {ThunkDispatch} from 'redux-thunk';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import moment from 'moment';

import * as types from 'store/db/types';
import * as actions from 'store/db/actions';
import * as utils from 'utils';

import Breadcrumb from 'components/Breadcrumb';
import AdSense from 'components/AdSense';
import DatePicker from 'components/DatePicker';
import Loading from 'components/Common/Loading';

interface DispatchProps {
  addMatchesPerDay: (competitionId: number, day: Date) => void;
}

interface Props extends DispatchProps, WithTranslation {
  competition: types.Competition | null;
  matchesPerDay: types.MatchesPerDay[];
}
interface State {
  selectedDate: Date;
  loading: boolean;
}

class MatchesDay extends Component<Props, State> {

  state = {
    selectedDate: new Date(),
    loading: false
  };

  componentDidMount(): void {
    const day = moment(this.state.selectedDate).startOf('day').format('YYYY-MM-DD');
    if (this.props.competition && !this.props.matchesPerDay.find(item => item.day === day)) {
      this.setState({loading: true});
      this.props.addMatchesPerDay(this.props.competition.id, this.state.selectedDate);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevState.selectedDate !== this.state.selectedDate && this.props.competition) {
      const day = moment(this.state.selectedDate).startOf('day').format('YYYY-MM-DD');
      if (!this.props.matchesPerDay.find(item => item.day === day)) {
        this.setState({loading: true});
        this.props.addMatchesPerDay(this.props.competition.id, this.state.selectedDate);
      }
    }
    if (prevProps.matchesPerDay.length !== this.props.matchesPerDay.length) {
      this.setState({loading: false});
    }
  }

  render() {
    const { t, competition } = this.props;
    if (!competition) {
      return t('NO_COMPETITION_SELECTED');
    }
    return (
        <div>
          <Breadcrumb competition={competition} title="MATCHES_DAY_TITLE" />
          <p dangerouslySetInnerHTML={{__html: t('MATCHES_DAY_TEXT')}}/>
          <DatePicker
              placeholder=""
              value={this.state.selectedDate}
              onChange={(date) => this.setState({selectedDate: date || new Date()})}
          />
          {this.renderDay()}
          {this.state.loading && <Loading />}
          <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
        </div>
    );
  }

  renderDay = () => {
    const {t, matchesPerDay, competition} = this.props;

    const day = moment(this.state.selectedDate).startOf('day').format('YYYY-MM-DD');
    const dayMatches = matchesPerDay.find(item => item.day === day);
    if (!dayMatches) {
      return null;
    }
    else if (dayMatches.matches.length === 0) {
      return <div>{t('NO_MATCHES_FOR_DAY') as string}</div>
    }

    let currentDateTime = 0;
    return (
        <Fragment>
          <div className="table">
            <table>
              <tbody>
              <tr>
                <th>{t('PLAYERS') as string}</th>
                <th/>
                <th/>
                {competition && competition.use_court && <th>{t('COURT') as string}</th>}
                <th>{t('RESULT') as string}</th>
                <th className="hide1">{t('REMARK') as string}</th>
              </tr>
              {dayMatches.matches
                  .filter(match => match.registrations.length > 0)
                  .sort((a, b) => utils.sortMatches(a, b))
                  .map((match, idx) => {
                    let dateHeader = null;
                    if (match.date_time && moment(match.date_time).unix() !== currentDateTime) {
                      currentDateTime = moment(match.date_time).unix();
                      if (moment.unix(currentDateTime).year() === 1970) {
                        dateHeader = (<tr className="dateHeader"><td colSpan={6}>
                          <i>{t('NOT_PLANNED') as string}</i>
                        </td></tr>)
                      } else {
                        dateHeader = (<tr className="dateHeader">
                          <td colSpan={6}>
                            <i>{moment(match.date_time).format('HH:mm')}</i>
                          </td>
                        </tr>)
                      }
                    }
                    return (<Fragment key={idx}>
                      {dateHeader}
                      <tr>
                        <td>{utils.getRegistrationName(t, match, 'H')}</td>
                        <td>-</td>
                        <td>{utils.getRegistrationName(t, match, 'A')}</td>
                        {competition && competition.use_court && <td>{match.court}</td>}
                        <td>{match.result}</td>
                        <td className="hide1">{match.remark}</td>
                      </tr>
                    </Fragment>)
                  })}
              </tbody>
            </table>
          </div>
        </Fragment>
    )
  };
}

const mapStateToProps = (state: DbReducer) => state.db;

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => ({
  addMatchesPerDay: (competitionId: number, day: Date) =>
      dispatch(actions.addMatchesPerDay(competitionId, day))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MatchesDay));