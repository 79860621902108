import React, {useEffect, useState, MouseEvent as ReactMouseEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';

import * as api from '../../../api';
import * as types from '../../../store/db/types';
import Breadcrumb from '../../../components/Breadcrumb';

import AdSense from '../../../components/AdSense';
import {toast} from 'react-toastify';

interface LocationState {
  competitionType: types.CompetitionType | undefined;
  competition: types.Competition;
}

const EditCompetitionType = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as LocationState

  const [competitionType, setCompetitionType] = useState<types.CompetitionType>({
    id: locationState.competitionType?.id || null,
    competition_id: locationState.competition.id,
    name: locationState.competitionType?.name || types.CompetitionTypeName.LADIES_SINGLE,
    count_type: locationState.competitionType?.count_type || types.CountType.TWO_FULL_SETS,
    registration_fee: locationState.competitionType?.registration_fee || null
  });

  useEffect(() => {
    if (locationState && locationState.competitionType) {
      setCompetitionType(locationState.competitionType);
    }
  }, [locationState]);

  const onSave = async (e: ReactMouseEvent<HTMLInputElement, MouseEvent>) => {
    try {
      if (competitionType.id) {
        await api.put(`competitions/${locationState.competition.id}/type`, competitionType);
      } else {
        await api.post(`competitions/${locationState.competition.id}/type`, competitionType);
      }
      toast(t('SAVE_SUCCESS') as string, {type: toast.TYPE.SUCCESS});
    } catch (e) {
      toast(t('SAVE_ERROR') as string, {type: toast.TYPE.ERROR});
    }

    history.goBack();
    e.preventDefault();
  };

  return (
      <div>
        <Breadcrumb title={'COMPETITION_TYPES'} />
        <p dangerouslySetInnerHTML={{__html: t('EDIT_COMPETITION_TYPE_TEXT') as string}} />
        <label>{t('TYPE') as string}</label>
        <select
            value={competitionType.name}
            required
            onChange={(event) => setCompetitionType({...competitionType, name: +event.target.value})}
        >
          <optgroup label={t('SINGLE') as string}>
            <option value={types.CompetitionTypeName.LADIES_SINGLE}>{t('LADIES_SINGLE') as string}</option>
            <option value={types.CompetitionTypeName.GENTLEMEN_SINGLE}>{t('GENTLEMEN_SINGLE') as string}</option>
          </optgroup>
          <optgroup label={t('DOUBLE_PERMANENT') as string}>
            <option value={types.CompetitionTypeName.LADIES_DOUBLE_PERMANENT_PARTNER}>{t('LADIES_DOUBLE_PERMANENT_PARTNER') as string}</option>
            <option value={types.CompetitionTypeName.GENTLEMEN_DOUBLE_PERMANENT_PARTNER}>{t('GENTLEMEN_DOUBLE_PERMANENT_PARTNER') as string}</option>
            <option value={types.CompetitionTypeName.MIXED_DOUBLE_PERMANENT_PARTNER}>{t('MIXED_DOUBLE_PERMANENT_PARTNER') as string}</option>
            <option value={types.CompetitionTypeName.DOUBLE_PERMANENT_PARTNER}>{t('DOUBLE_PERMANENT_PARTNER') as string}</option>
          </optgroup>
          <optgroup label={t('DOUBLE_VARYING') as string}>
            <option value={types.CompetitionTypeName.LADIES_DOUBLE_VARYING_PARTNER}>{t('LADIES_DOUBLE_VARYING_PARTNER') as string}</option>
            <option value={types.CompetitionTypeName.GENTLEMEN_DOUBLE_VARYING_PARTNER}>{t('GENTLEMEN_DOUBLE_VARYING_PARTNER') as string}</option>
            <option value={types.CompetitionTypeName.DOUBLE_VARYING_PARTNER}>{t('DOUBLE_VARYING_PARTNER') as string}</option>
          </optgroup>
        </select>
        <label>{t('COUNT_TYPE') as string}</label>
        <select
            value={competitionType.count_type}
            required
            onChange={(event) => setCompetitionType({...competitionType, count_type: +event.target.value})}
        >
          <option value={types.CountType.TWO_FULL_SETS}>{t('TWO_FULL_SETS') as string}</option>
          <option value={types.CountType.TWO_SETS_EQUALS_ON_6}>{t('TWO_SETS_EQUALS_ON_6') as string}</option>
          <option value={types.CountType.BEST_OF_THREE}>{t('BEST_OF_THREE') as string}</option>
          <option value={types.CountType.COUNT_GAMES}>{t('COUNT_GAMES') as string}</option>
        </select>
        <label>{t('REGISTRATION_FEE') as string}</label>
        <input
            type="number"
            step="0.50"
            min="0"
            max="20"
            value={competitionType.registration_fee || undefined}
            placeholder={t('REGISTRATION_FEE') as string}
            onChange={(event) => setCompetitionType({...competitionType, registration_fee: +event.target.value})}
        />
        <input
            type="button"
            value={'' + t('SAVE') as string}
            onClick={onSave}
        />
        <input
            type="button" className="second"
            value={'' + t('CANCEL') as string}
            onClick={() => history.goBack()}
        />
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
};

export default EditCompetitionType;