import React, {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import moment from 'moment';

import Confirm from 'components/Common/Confirm';

import * as types from 'store/db/types';
import * as utils from 'utils';
import * as api from 'api';

// import styles from './CourtsScheduleList.module.scss';

interface Props {
  competition: types.Competition;
  matches: types.Match[];
  onDelete(id: number): void;
}

const CourtsScheduleList = ({competition, matches, onDelete}: Props) => {
  const {t} = useTranslation();

  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [selectedMatch, setSelectedMatch] = useState<types.Match>();

  const removeCourt = async () => {
    setShowConfirm(false);
    if (!selectedMatch) {
      toast(t('MATCH_NOT_FOUND') as string);
      return;
    }

    try {
      await api.del(`matches/${competition.id}/${selectedMatch.id}`);
      onDelete(selectedMatch.id);
      toast(t('SAVE_SUCCESS') as string, {type: toast.TYPE.SUCCESS});
    } catch (e) {
      toast(t('SAVE_ERROR') as string, {type: toast.TYPE.ERROR});
    }
  }

  const renderMatches = () => {
    return (
      <div className="table">
        <table>
          <tbody>
            <tr>
              <th>{t('DATE_TIME') as string}</th>
              {competition.use_court && <th>{t('COURT') as string}</th>}
              <th>{t('PLAYERS') as string}</th>
              <th />
              <th />
              <th />
            </tr>
            {matches
                .sort((a, b) => moment(a.date_time || '1970-01-01').unix() - moment(b.date_time || '1970-01-01').unix())
                .map((match: types.Match, idx: number) => {
                  return (<Fragment key={idx}>
                    <tr>
                      <td>{utils.formatDateTime(t, match.date_time)}</td>
                      {competition.use_court && <td>{match.court}</td>}
                      <td>{utils.getRegistrationName(t, match, 'H')}</td>
                      <td>-</td>
                      <td>{utils.getRegistrationName(t, match, 'A')}</td>
                      <td>{renderRemoveMatchIcon(match)}</td>
                    </tr>
                  </Fragment>)
                })}
          </tbody>
        </table>
      </div>
    )
  };

  const renderRemoveMatchIcon = (match: types.Match) => {
    if (match.registrations.length > 0) {
      return null;
    }

    return (
      <i className="far fa-trash-alt" style={{float: 'right'}}
           onClick={() => {
             setSelectedMatch(match);
             setShowConfirm(true);
           }} />
    );
  }

  const renderPopups = () => {
    if (!showConfirm) {
      return null;
    }
    if (showConfirm) {
      return <Confirm
        title={t('REMOVE_COURT_TITLE')}
        message={t('REMOVE_COURT_MESSAGE', {
          date_time: utils.formatDateTime(t, selectedMatch!.date_time),
          court: selectedMatch!.court
        })}
        onNo={() => setShowConfirm(false)}
        onYes={removeCourt}
      />;
    }
    return null;
  };

  return (
      <div>
        {renderMatches()}
        {renderPopups()}
      </div>
  );
}

export default CourtsScheduleList;