export const ADD_MATCHES = 'ADD_MATCHES';
export const ADD_MATCHES_PER_DAY = 'ADD_MATCHES_PER_DAY';
export const CLEAR_MATCHES = 'CLEAR_MATCHES';
export const GET_CLUBS = 'GET_CLUBS';
export const GET_COMMON_NEWS = 'GET_COMMON_NEWS';
export const GET_COMPETITION = 'GET_COMPETITION';
export const GET_COMPETITION_NEWS = 'GET_COMPETITION_NEWS';
export const GET_GROUPS = 'GET_GROUPS';
export const GET_USER_MATCHES = 'GET_USER_MATCHES';
export const SET_CLUB_ID = 'SET_CLUB_ID';
export const STORE_PLAYER = 'GET_PLAYER';
export const UPDATE_MATCH = 'UPDATE_MATCH';

export interface TimeAvailability {
  time: string;
  available?: boolean;
}

export interface DayAvailability {
  weekday: number;
  times: TimeAvailability[];
}

export interface Availability {
  week: DayAvailability[];
  unavailableDates: string[];
}

export interface Available {
  id: number
  date_time: string;
  court: string;
}

export interface Club {
  id: number;
  name: string;
}

export interface Competition {
  id: number;
  club_id: number;
  club_name: string;
  email: string;
  manager: string;
  name: string;
  competition_admins: CompetitionAdmin[];
  sport: string;
  use_court: boolean;
  move_match: 0 | 1 | 2; // 0 = No, 1 = Manager, 2 = Player
  use_availability: boolean;
  registration_from?: string;
  registration_to?: string;
  matches_count?: number;
  first_match?: string;
  last_match?: string;
}

export interface CompetitionAdmin {
  id?: number;
  player_id: number;
}

export interface CompetitionType {
  id: number | null;
  competition_id: number;
  name: CompetitionTypeName;
  count_type: CountType;
  registration_fee: number | null;
}

export enum Gender {
  MALE=1,
  FEMALE=2
}

export enum CompetitionStatus {
  STATUS_IN_PREPARATION= 'STATUS_IN_PREPARATION',
  STATUS_REGISTRATION_OPEN= 'STATUS_REGISTRATION_OPEN', STATUS_REGISTRATION_CLOSED = 'STATUS_REGISTRATION_CLOSED',
  STATUS_ACTIVE= 'STATUS_ACTIVE', STATUS_DONE= 'STATUS_DONE'
}

export enum CompetitionTypeName {
  LADIES_SINGLE, GENTLEMEN_SINGLE, LADIES_DOUBLE_PERMANENT_PARTNER, GENTLEMEN_DOUBLE_PERMANENT_PARTNER,
  MIXED_DOUBLE_PERMANENT_PARTNER, DOUBLE_PERMANENT_PARTNER, LADIES_DOUBLE_VARYING_PARTNER,
  GENTLEMEN_DOUBLE_VARYING_PARTNER, DOUBLE_VARYING_PARTNER
}

export enum PartnerGender {
  NO_PARTNER, MAN, WOMAN, OTHER_GENDER, ALL
}

export const getPartnerGender = (nr: number): PartnerGender => {
  if (nr === CompetitionTypeName.GENTLEMEN_DOUBLE_PERMANENT_PARTNER || nr === CompetitionTypeName.GENTLEMEN_DOUBLE_VARYING_PARTNER) {
    return PartnerGender.MAN;
  }
  if (nr === CompetitionTypeName.LADIES_DOUBLE_PERMANENT_PARTNER || nr === CompetitionTypeName.LADIES_DOUBLE_VARYING_PARTNER) {
    return PartnerGender.WOMAN;
  }
  if (nr === CompetitionTypeName.MIXED_DOUBLE_PERMANENT_PARTNER) {
    return PartnerGender.OTHER_GENDER;
  }
  if (nr === CompetitionTypeName.DOUBLE_PERMANENT_PARTNER || nr === CompetitionTypeName.DOUBLE_VARYING_PARTNER) {
    return PartnerGender.ALL;
  }
  return PartnerGender.NO_PARTNER;
}

export const isCompetitionTypeWithPermanentPartner = (nr?: number): boolean => {
  return nr === CompetitionTypeName.LADIES_DOUBLE_PERMANENT_PARTNER.valueOf()
      || nr === CompetitionTypeName.GENTLEMEN_DOUBLE_PERMANENT_PARTNER.valueOf()
      || nr === CompetitionTypeName.MIXED_DOUBLE_PERMANENT_PARTNER.valueOf()
      || nr === CompetitionTypeName.DOUBLE_PERMANENT_PARTNER.valueOf();
}

export const isCompetitionTypeWithVaryingPartner = (nr?: number): boolean => {
  return nr === CompetitionTypeName.LADIES_DOUBLE_VARYING_PARTNER.valueOf()
      || nr === CompetitionTypeName.GENTLEMEN_DOUBLE_VARYING_PARTNER.valueOf()
      || nr === CompetitionTypeName.DOUBLE_VARYING_PARTNER.valueOf();
}

export const isCompetitionTypeForMen = (nr: number): boolean => {
  return nr === CompetitionTypeName.GENTLEMEN_SINGLE.valueOf()
      || nr === CompetitionTypeName.GENTLEMEN_DOUBLE_PERMANENT_PARTNER.valueOf()
      || nr === CompetitionTypeName.GENTLEMEN_DOUBLE_VARYING_PARTNER.valueOf()
      || nr === CompetitionTypeName.MIXED_DOUBLE_PERMANENT_PARTNER.valueOf()
      || nr === CompetitionTypeName.DOUBLE_PERMANENT_PARTNER.valueOf()
      || nr === CompetitionTypeName.DOUBLE_VARYING_PARTNER.valueOf();
}

export const isCompetitionTypeForWomen = (nr: number): boolean => {
  return nr === CompetitionTypeName.LADIES_SINGLE.valueOf()
      || nr === CompetitionTypeName.LADIES_DOUBLE_PERMANENT_PARTNER.valueOf()
      || nr === CompetitionTypeName.LADIES_DOUBLE_VARYING_PARTNER.valueOf()
      || nr === CompetitionTypeName.MIXED_DOUBLE_PERMANENT_PARTNER.valueOf()
      || nr === CompetitionTypeName.DOUBLE_PERMANENT_PARTNER.valueOf()
      || nr === CompetitionTypeName.DOUBLE_VARYING_PARTNER.valueOf();
}

export enum CountType {
  TWO_FULL_SETS, TWO_SETS_EQUALS_ON_6, BEST_OF_THREE, COUNT_GAMES
}

export interface CourtsOverview {
  firstDate: string;
  lastDate: string;
  totalMatches: number;
}

export interface Group {
  id: number;
  name: string;
  competition_type: CompetitionType;
}

export interface Match {
  id: number;
  registrations: MatchRegistration[];
  court: string | null;
  date_time: string | null;
  group_id: number | null;
  group_name: string | null;
  remark: string | null;
  result: string | null;
  type: number | null;
  count_type: number | null;
}

export interface MatchRegistration {
  registration_id: number;
  registration_name: string;
  player1_id: number;
  player1: string;
  player2_id?: number;
  player2?: string;
  home_away: 'H' | 'A';
  reserve_played: boolean;
}

export interface MatchDate {
  date: string;
}

export interface MatchesPerDay {
  day: string;
  matches: Match[];
}

export interface WeekPlanning {
  day: number;
  times: string[];
}

export interface Player {
  id: number;
  email: string;
  name: string;
  gender: 1 | 2; // 1 = Male, 2 = Female
  level_single: string | null;
  level_double: string | null;
  level_padel: string | null;
  mobile: string;
  number: string | null;
  phone: string | null;
  clubs: number[];
  username?: string;
  competitions?: string[];
}

export interface Registration {
  id?: number;
  group?: Group;
  competition_type: CompetitionType;
  player1: Player;
  player2?: Player;
  partner?: string;
  // group_id: number | null;
}

export interface RegistrationsPerType {
  competitionTypeName: CompetitionTypeName;
  registrations: number;
}

export interface News {
  id: number;
  content: string;
  from: string;
  until: string;
}

export interface User {
  id: number;
  name: string;
  player: Player;
}
