import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Editor} from '@tinymce/tinymce-react';

import Breadcrumb from '../../components/Breadcrumb';

import * as types from '../../store/db/types';
import * as api from '../../api';
import AdSense from '../../components/AdSense';

interface LocationState {
  competition: types.Competition;
}

const Regulations = (): JSX.Element => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as LocationState

  const [regulations, setRegulations] = useState<string>();
  const [competitionId, setCompetitionId] = useState<number>();

  useEffect(() => {
    if (regulations) {
      return;
    }
    const competitionId = locationState.competition.id;
    if (competitionId) {
      setCompetitionId(competitionId);
      api.get(`competitions/${competitionId}/regulations`).then(regulations => {
        setRegulations(regulations && regulations.regulations ? regulations.regulations : t('ENTER_REGULATIONS'))});
    }
  }, [locationState, regulations, t]);

  const onSave = async () => {
    try {
      await api.put(`competitions/${competitionId}/regulations`, {'regulations': regulations});

      toast(t('SAVE_SUCCESS') as string, {type: toast.TYPE.SUCCESS});

      history.goBack();
    } catch (e) {
      toast(t('SAVE_ERROR') as string, {type: toast.TYPE.ERROR})
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  if (!regulations) {
    return <div />;
  }
  return (
      <div>
        <Breadcrumb competition={locationState.competition} title="REGULATIONS_TITLE"/>
        <Editor
          tinymceScriptSrc={process.env.PUBLIC_URL + '/js/tinymce/tinymce.min.js'}
          init={{
            height: '500',
            menubar: false,
            plugins: 'code link lists table',
            toolbar2: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | link | code'
          }}
          value={regulations}
          onEditorChange={(value) => setRegulations(value)}
        />
        <input
            type="button"
            value={'' + t('SAVE') as string}
            className="button"
            onClick={onSave}
        />
        <input
            type="button"
            value={'' + t('CANCEL') as string}
            className="button"
            onClick={onCancel}
        />
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
}

export default Regulations;
