import React, { useEffect, useState } from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

import * as api from '../../api';
import Loading from 'components/Common/Loading';

const VerifyEmail = () => {
  const {t} = useTranslation();
  const location = useLocation();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [isValidEmail, setValidEmail] = useState<boolean>(false);

  const id = new URLSearchParams(location.search).get('id');
  const hash = new URLSearchParams(location.search).get('hash');

  useEffect(() => {
    (async () => {
      try {
        await api.get(`auth/verify-email/${id}/${hash}`);
        setValidEmail(true);
      } finally {
        setLoading(false);
      }
      setLoading(false);
    })();
  }, [id, hash]);

  if (isLoading) {
    return <Loading />;
  }

  if (!isValidEmail) {
    return <p>{t('VERIFY_EMAIL_NOT_VALID') as string}</p>
  }

  return <p>{t('VERIFY_EMAIL_VALID') as string}</p>
}

export default VerifyEmail;
