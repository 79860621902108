import React, {useEffect, useState} from 'react';
import {DbReducer} from '../../store/db/reducer';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import * as types from 'store/db/types';
import * as api from 'api';

import { getTable } from './RegistrationResults';
import Breadcrumb from 'components/Breadcrumb';
import SelectGroup from 'components/SelectGroup';
import AdSense from 'components/AdSense';

import styles from './MatchesGroup.module.scss';

const Tables = (): JSX.Element => {
  const {t} = useTranslation();

  const [matches, setMatches] = useState<types.Match[]>([]);
  const [groups, setGroups] = useState<types.Group[]>([]);
  const [group, setGroup] = useState<string>('none');

  const competition: types.Competition | null = useSelector<DbReducer, types.Competition | null>(state => state.db.competition);

  useEffect(() => {
    (async () => {
      if (competition) {
        if (groups.length === 0) {
          setGroups(await api.get(`groups/${competition.id}`));
        }

        if (group !== 'none') {
          setMatches(await api.get(`matches/group/${group}`));
        }
      }
    })();
  }, [competition, group, groups.length]);


  const renderTable = () => {
    if (group === 'none') {
      return null;
    }

    const groupMatches = matches.filter(match => {return match.group_id == +group});
    if (groupMatches.length === 0) {
      return null;
    }
    const table = getTable(groupMatches);
    const showSets = groupMatches[0].count_type !== types.CountType.COUNT_GAMES;

    return (
      <div className="table">
        <table>
          <tbody>
          <tr>
            <th className="hide1">{t('POSITION') as string}</th>
            <th>{t('PLAYERS') as string}</th>
            <th>{t('PLAYED') as string}</th>
            <th className="hide1">{t('MATCHES') as string}</th>
            {showSets ? <th>{t('SETS') as string}</th> : null}
            <th className="hide1">{t('GAMES') as string}</th>
            <th>{t('POINTS') as string}</th>
          </tr>
          {table.map((item, idx) => (
              <tr key={idx}>
                <td className="hide1">{idx + 1}</td>
                <td>{item.registration}</td>
                <td>{item.played}</td>
                <td className="hide1">{item.matchesFor}-{item.matchesAgainst}</td>
                {showSets ? <td>{item.setsFor}-{item.setsAgainst}</td> : null}
                <td className="hide1">{item.gamesFor}-{item.gamesAgainst}</td>
                <td>{item.points}</td>
              </tr>
          ))}
          </tbody>
        </table>
      </div>
    )
  };

  if (!competition) {
    return <div>{t('NO_COMPETITION_SELECTED') as string}</div>;
  }

  return (
      <div className={styles.MatchesGroup}>
        <Breadcrumb competition={competition} title={'TABLES_TITLE'}/>
        <p dangerouslySetInnerHTML={{__html: t('TABLES_TEXT') as string}}/>
        <SelectGroup groups={groups} onChange={group => setGroup(group)} />
        {renderTable()}
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );

}

export default Tables;