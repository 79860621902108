import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';

import styles from './Breadcrumb.module.scss';
import * as types from '../store/db/types';

interface Props extends WithTranslation {
  competition?: types.Competition;
  title: string
}

const Breadcrumb = ({ t, competition, title }: Props) => {
  if (competition) {
    return (
        <ul className={styles.Breadcrumb}>
          <li>{competition.club_name}</li>
          <li>{competition.name}</li>
          <li>{t(title) as string}</li>
        </ul>
    );
  }
  return (
      <ul className={styles.Breadcrumb}>
        <li>Home</li>
        <li>{t(title) as string}</li>
      </ul>
  );
};

export default withTranslation()(Breadcrumb);