import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';

import * as api from 'api';
import * as types from 'store/db/types';
import Breadcrumb from 'components/Breadcrumb';
import AdSense from 'components/AdSense';
import Loading from 'components/Common/Loading';

import styles from './CreateMatchSchedule.module.scss';

interface LocationState {
  competition: types.Competition;
  competitionTypes: types.CompetitionType[];
  groups: types.Group[];
  totalMatches: number;
}

const CreateMatchSchedule = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as LocationState;

  const [rounds, setRounds] = useState<number[]>(locationState.competitionTypes.map((group, index) => 1));
  const [planMatches, setPlanMatches] = useState<boolean[]>(locationState.competitionTypes.map((group, index) => true));
  const [registrations, setRegistrations] = useState<types.Registration[]>([]);
  const [countRoundsChange, setCountRoundsChange] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() =>{
    (async () => {
      setRegistrations(await api.get(`registrations/all/${locationState.competition.id}`));
      setLoading(false);
    })();
  }, [locationState.competition]);

  const onSave = async () => {
    const body = locationState.competitionTypes.map((type, index) => ({
      id: type.id,
      name: type.name,
      rounds: rounds[index],
      planMatches: planMatches[index]
    }));
    try {
      await api.post(`matches/${locationState.competition.id}/create-schedule`, body);
      toast(t('SAVE_SUCCESS') as string, {type: toast.TYPE.SUCCESS});
      history.goBack();
    } catch (e) {
      toast(t('SAVE_ERROR') as string, {type: toast.TYPE.ERROR});
    }
  }

  const renderTotalMatches = () => {
    let matches = 0;
    locationState.competitionTypes.forEach((type, index) => {
      matches += getMatchesForType(type, index);
    });

    if (matches > locationState.totalMatches) {
      return <p className="error">{t('COMPETITION_TYPE_TOTAL_MATCHES_ERROR', {total: locationState.totalMatches, type: matches}) as string}</p>
    }
    return <p>{t('COMPETITION_TYPE_TOTAL_MATCHES', {total: locationState.totalMatches, type: matches}) as string}</p>
  }

  const hasError = (): boolean => {
    let matches = 0;
    locationState.competitionTypes.forEach((type, index) => {
      if (hasTypeErrors(type)) {
        return true;
      }
      matches += getMatchesForType(type, index);
    });

    return matches > locationState.totalMatches;
  }

  const renderRounds = (index: number) => {
    return (
      <select defaultValue={rounds[index]} onChange={(event) => {
          const curRounds = rounds;
          curRounds[index] = +event.target.value;
          setRounds(curRounds);
          setCountRoundsChange(countRoundsChange + 1);
        }}>
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
      </select>
    );
  }

  const renderPlanMatches = (index: number) => {
    return (
      <input type="checkbox" defaultChecked={true} onChange={(event) => {
        const curPlanMatches = planMatches;
        curPlanMatches[index] = event.target.value === 'checked';
        setPlanMatches(curPlanMatches);
      }}/>
    );
  }

  const renderGroups = (type: types.CompetitionType, index: number) => {
    return <div>{locationState.groups.filter(group => group.competition_type.id === type.id).length}</div>
  }

  const renderRegistrations = (type: types.CompetitionType, index: number) => {
    return <div>{registrations.filter(reg => reg.competition_type.id === type.id).length}</div>
  }

  const renderMatches = (type: types.CompetitionType, index: number) => {
    return <div>{getMatchesForType(type, index)}</div>;
  }

  const getMatchesForType = (type: types.CompetitionType, index: number) => {
    let matches = 0;
    const groups = locationState.groups.filter(group => group.competition_type.id === type.id);
    groups.forEach(group => {
      const groupRegistrations = registrations.filter(reg => reg.group?.id === group.id).length;
      const groupRounds = rounds[index];
      if (types.isCompetitionTypeWithVaryingPartner(group.competition_type.name)) {
        if (groupRegistrations === 8) {
          matches += (groupRounds * 14);
        }
      } else {
        matches += ((groupRegistrations / 2) * (groupRegistrations - 1)) * groupRounds;
      }
    });

    return matches;
  }

  const renderTypeErrors = (type: types.CompetitionType) => {
    if (hasTypeErrors(type)) {
      return <p className="error">{t('VALIDATION_VARYING_PARTNER_GROUP_NOT_8') as string}</p>;
    }
    return null;
  }

  const hasTypeErrors = (type: types.CompetitionType): boolean => {
    if (!types.isCompetitionTypeWithVaryingPartner(type.name)) {
      return false;
    }
    const groups = locationState.groups.filter(group => group.competition_type.id === type.id);
    for (const group of groups) {
      const groupRegistrations = registrations.filter(reg => reg.group?.id === group.id).length;
      if (groupRegistrations !== 8) {
        return true;
      }
    }

    return false;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
      <div className={styles.CreateMatchSchedule}>
        <Breadcrumb title={'CREATE_MATCH_SCHEDULE'} />
        <p dangerouslySetInnerHTML={{__html: t('CREATE_MATCH_SCHEDULE_TEXT', {
          rounds: t('CREATE_MATCH_SCHEDULE_ROUNDS'),
          planmatches: t('CREATE_MATCH_SCHEDULE_PLAN_MATCHES')
        }) as string}} />
        <input
            type="button"
            value={'' + t('CREATE_MATCH_SCHEDULE') as string}
            className="button"
            onClick={() => onSave()}
            disabled={hasError()}
        />
        <input
            type="button"
            value={'' + t('BACK') as string}
            className="button"
            onClick={() => history.goBack()}
        />
        <Fragment>{renderTotalMatches()}</Fragment>
        <div className="table">
          <table>
            <tbody>
            <tr>
              <th>{t('COMPETITION_TYPE') as string}</th>
              <th>{t('ROUNDS') as string}</th>
              <th>{t('PLAN_MATCHES') as string}</th>
              <th>{t('REGISTRATIONS') as string}</th>
              <th>{t('GROUPS') as string}</th>
              <th>{t('MATCHES') as string}</th>
            </tr>
            {locationState.competitionTypes
                .map((type, index) => {
                  return (
                      <tr key={type.id || undefined}>
                        <td>{t(types.CompetitionTypeName[type.name]) as string}{renderTypeErrors(type)}</td>
                        <td>{renderRounds(index)}</td>
                        <td>{renderPlanMatches(index)}</td>
                        <td>{renderRegistrations(type, index)}</td>
                        <td>{renderGroups(type, index)}</td>
                        <td>{renderMatches(type, index)}</td>
                      </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
};

export default CreateMatchSchedule;