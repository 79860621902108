import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import AdSense from 'components/AdSense';
import Breadcrumb from 'components/Breadcrumb';
import Select from 'components/Common/Select';
import Table, {Icon, Row} from 'components/Common/Table';

import * as api from 'api';
import * as types from 'store/db/types';
import * as utils from 'utils';

const Registrations = () => {
  const competition = useSelector((state: any) => state.db.competition);

  const {t} = useTranslation();

  const [registrations, setRegistrations] = useState<types.Registration[]>([]);
  const [competitionTypes, setCompetitionTypes] = useState<types.CompetitionType[]>([]);
  const [groups, setGroups] = useState<types.Group[]>([]);
  const [selectedCompetitionType, setSelectedCompetitionType] = useState<types.CompetitionType>();

  useEffect(() => {
    (async () => {
      if (competition && registrations.length === 0) {
          const [competitionTypes, regs, groups] = await Promise.all([
            api.get(`competitions/${competition.id}/types`),
            api.get(`registrations/all/${competition.id}`),
            api.get(`groups/${competition.id}`)
          ]);
          setCompetitionTypes(competitionTypes);
          setRegistrations(regs);
          setGroups(groups);
          if (!selectedCompetitionType && competitionTypes.length > 0) {
            setSelectedCompetitionType(competitionTypes[0]);
          }
      }
    })();
  }, [competition, registrations.length, selectedCompetitionType]);

  const renderTable = () => {
    if (!selectedCompetitionType) {
      return null;
    }

    const rows: Row[] = registrations
        .filter(reg => reg.competition_type.id === selectedCompetitionType.id)
        .map((registration, index) => {
          const columns: string[] = [];
          columns.push(`${index + 1}`);
          columns.push(utils.getName(t, registration));
          const group = groups.find(item => item.id === registration.group?.id);
          columns.push(group ? group.name : '');

          const icons: Icon[] = [];
          return { item: registration, columns, icons };
        });

    return (
      <Table
        header={['', t('REGISTRATION'), t('GROUP')]}
        rows={rows}
      />);
  };

  if (!competition) {
    return <div>{t('NO_COMPETITION_SELECTED') as string}</div>;
  }

  return (
      <div>
        <div>
          <Breadcrumb competition={competition} title={'REGISTRATIONS_TITLE'} />
          <p dangerouslySetInnerHTML={{__html: t('REGISTRATIONS_TEXT')}} />
          <div className="filter">
            <Select
                defaultValue={selectedCompetitionType && `${t(types.CompetitionTypeName[selectedCompetitionType.name])}`}
                placeholder={t('SELECT_COMPETITION_TYPE') as string}
                canSelectAll={false}
                options={competitionTypes.map((type: types.CompetitionType) => {
                  const key = type;
                  const value = `${t(types.CompetitionTypeName[type.name])}`;
                  return {key, value};
                })}
                onChange={(option: types.CompetitionType) => setSelectedCompetitionType(option)} />
          </div>
          {renderTable()}
        </div>
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
}

export default Registrations;