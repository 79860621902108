import React, {Component} from 'react';
import {ThunkDispatch} from 'redux-thunk';
import {connect} from 'react-redux';
import {withTranslation, WithTranslation} from 'react-i18next';

import {DbReducer} from 'store/db/reducer';
import * as actions from 'store/db/actions';
import * as types from 'store/db/types';
import * as api from 'api';

interface DispatchProps {
  clearMatches: () => void;
  getClubs: () => void;
  getCompetition: (competitionId: number) => void;
  getGroups: (competitionId: number) => void;
}

interface Props extends DispatchProps, WithTranslation {
  clubs: types.Club[];
  competition: types.Competition | null;
  onClose(): void;
}
interface State {
  clubId: number | null;
  competitionId: string | null;
  competitions: types.Competition[];
}

class SelectCompetition extends Component<Props, State> {

  state = {
    clubId: null,
    competitionId: null,
    competitions: []
  };

  async componentDidMount() {
    const {competition, getClubs, clubs} = this.props;
    if (clubs.length === 0) {
      getClubs();
    }
    if (competition) {
      const competitions = await this.getCompetitions(competition.club_id);
      this.setState({clubId: competition.club_id, competitionId: localStorage.getItem('competitionId'),
        competitions});
    }
  }

  changeClub = async (event: any) => {
    const clubId = event.target.value;
    const competitions = await this.getCompetitions(clubId);
    this.setState({clubId: clubId, competitions,
      competitionId: competitions.length > 0 ? competitions[0].id : null});
  };

  changeCompetition = (event: any) => {
    const competitionId = event.target.value;
    this.setState({competitionId: competitionId});
  };

  getCompetitions = async (clubId: number) => {
    return await api.get(`competitions?clubId=${clubId}`);
  };

  onSelect = () => {
    const competitionId = this.state.competitionId;
    if (competitionId) {
      this.props.getGroups(competitionId);
      this.props.clearMatches();
      localStorage.setItem('competitionId', competitionId);
      this.props.getCompetition(competitionId);
    }
    this.props.onClose();
  };

  render(): React.ReactNode {
    const { t } = this.props;

    return (
        <div className="popup">
          <div className="header">{t('SELECT_COMPETITION') as string}</div>
          <div className="content">
            {t('SELECT_CLUB') as string}<br />
            {this.renderClubs()}
            {t('SELECT_COMPETITION') as string}<br />
            {this.renderCompetitions()}
            <input
                type="button"
                value={'' + t('SELECT') as string}
                className="button"
                onClick={this.onSelect}
            />
            <input
                type="button" value={'' + t('CANCEL') as string}
                className="lastButton second"
                onClick={this.props.onClose}
            />
          </div>
        </div>
    );
  }

  renderClubs = () => {
    const clubId = this.state.clubId;
    return (
        <select  value={clubId ? clubId : undefined} onChange={this.changeClub}>
        {this.props.clubs.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
          </select>
    );
  };

  renderCompetitions = () => {
    const competitionId = this.state.competitionId;
    const competitions: types.Competition[] = this.state.competitions;
    return (
        <select value={competitionId ? competitionId : undefined} onChange={this.changeCompetition}>
          {competitions
            .sort((a, b) => `${this.props.t(a.sport.toUpperCase())} - ${a.name}`.localeCompare(`${this.props.t(b.sport.toUpperCase())} - ${b.name}`))
            .map(item => <option key={item.id} value={item.id}>{this.props.t(item.sport.toUpperCase()) as string} - {item.name}</option>)}
        </select>
    );
  };
}

const mapStateToProps = (state: DbReducer) => state.db;

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => ({
  clearMatches: () => dispatch(actions.clearMatches()),
  getClubs: () => dispatch(actions.getClubs()),
  getCompetition: (competitionId: number) => dispatch(actions.getCompetition(competitionId)),
  getGroups: (competitionId: number) => dispatch(actions.getGroups(competitionId))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SelectCompetition));