import React from 'react';
import {Route, Redirect} from 'react-router-dom';

import * as routes from '../constants/routes';

interface Props {
  render?: any;
  component?: any;
  authenticated: boolean;
  path: string;
}

const PrivateRoute = ({authenticated, path, component, render}: Props) => {
  if (authenticated) {
    if (component) {
      return <Route {...path} component={component}/>
    } else {
      return <Route {...path} render={render}/>
    }
  }

  return (
      <Redirect to={{
        pathname: routes.SIGN_IN,
        state: {
          from: path
        }
      }}/>
  );
};

export default PrivateRoute;