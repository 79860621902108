import React, {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import * as types from 'store/db/types';
import * as utils from 'utils';
import {Group} from 'store/db/types';

interface Props extends WithTranslation {
  canSelectAllGroups?: boolean;
  groups: types.Group[];
  onChange(group: string): void;
}
interface State {
  group: string
}

class SelectGroup extends Component<Props, State> {

  state = {
    group: 'none'
  };

  onChange = (event: any) => {
    const group = event.target.value;
    this.setState({group});
    this.props.onChange(group);
  };

  render() {
    const { t } = this.props;

    return (
        <select
            value={this.state.group}
            onChange={this.onChange}>
          {!this.props.canSelectAllGroups && <option value="none" disabled>{t('SELECT_GROUP') as string}</option>}
          {this.props.canSelectAllGroups && <option value="none" >{t('ALL_GROUPS') as string}</option>}
          {this.renderOptionGroups()}
        </select>
    )
  }

  renderOptionGroups = () => {
    const { t } = this.props;

    const competition_types: types.CompetitionType[] = [];
    this.props.groups.forEach(group => {
      if (!competition_types.find(ct => ct.id === group.competition_type.id)) {
        competition_types.push(group.competition_type);
      }
    })
    return competition_types
        .sort((a, b) => utils.sortCompetitionTypes(t, a, b))
        .map((type, idx) => (
            <optgroup label={"" + t(types.CompetitionTypeName[type.name])} key={idx}>
              {this.renderOptions(this.props.groups.filter(item => item.competition_type.id === type.id))}
            </optgroup>
        ));
  };

  renderOptions = (groups: Group[]) => {
    return groups
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(item => {
          return <option value={item.id} key={item.id}>{item.name}</option>
        })
  };
}

export default withTranslation()(SelectGroup);