import ErrorMessage from "components/Validation/ErrorMessage";
import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import ErrorMessageRequired from "../Validation/ErrorMessageRequired";

interface Props {
  register: any;
  errors: any;
}

const AccountName = ({register, errors}: Props) => {

  const [t] = useTranslation();

  return (
    <Fragment>
      <input
        name="accountName"
        ref={register({ required: true, maxLength: 50 })}
        type="text"
        placeholder={`${t('ACCOUNT_NAME')} *`}
      />
      <ErrorMessageRequired errors={errors} />
      <ErrorMessage errors={errors}  errorType="maxLength" message={t('VALIDATION_FIELD_MAXLENGTH', {length: "50"})} />
    </Fragment>
  )
}

export default AccountName;