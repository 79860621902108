import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import ErrorMessageRequired from "../Validation/ErrorMessageRequired";

interface Props {
  name: string;
  placeholderKey: string,
  register: any;
  errors: any;
  disablePlaceholder?: boolean
  newLayout?: boolean;
}

const PlayerLevel = ({name, placeholderKey, register, errors, disablePlaceholder, newLayout}: Props) => {

  const [t] = useTranslation();

  const options = [...Array(9)].map((_, i) => {
    return <option key={placeholderKey + i + 1} value={i + 1}>{i + 1}</option>
  })

  options.unshift(<option key={placeholderKey + 0} value={0} disabled={disablePlaceholder}>{t('SELECT_LEVEL') as string}</option>)

  return (
    <Fragment>
      {newLayout && <ErrorMessageRequired errors={errors} />}
      <select
        name={name}
        ref={register({
          required: true,
          validate: (value :number) => (value !== 0 || 'VALIDATION_FIELD_PLAYER_LEVEL_NOT_SELECTED')
        })}>
        {options}
      </select>
      {!newLayout && <ErrorMessageRequired errors={errors} />}
    </Fragment>)
}

export default PlayerLevel;