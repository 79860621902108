import React, {Fragment, useEffect, useState} from 'react';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import * as types from '../../store/db/types';
import * as utils from '../../utils';

import Breadcrumb from '../../components/Breadcrumb';
import AdSense from '../../components/AdSense';
import * as api from '../../api';

const AvailableCourts = () => {
  const {t} = useTranslation();
  const competition = useSelector((state: any) => state.db.competition);

  const [available, setAvailable] = useState<types.Available[]>([]);

  useEffect(() => {
    (async () => {
      if (competition && competition.move_match > 0 && competition.use_court) {
        setAvailable(await api.get(`matches/available/${competition.id}`));
      }
    })();
  }, [competition]);

  const renderCourts = () => {
    const now = moment().unix();
    return (
        <Fragment>
          <div className="table">
            <table>
              <tbody>
              <tr>
                <th>{t('DATE') as string}</th>
                <th>{t('TIME') as string}</th>
                <th>{t('COURT') as string}</th>
              </tr>
              {available
                  .filter(item => moment(item.date_time).unix() >= now)
                  .sort((a, b) => utils.sortAvailable(a, b))
                  .map((item, idx) => (
                  <tr key={idx}>
                    <td>{moment(item.date_time).format('DD-MM-YYYY')}</td>
                    <td>{moment(item.date_time).format('HH:mm')}</td>
                    <td>{item.court}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Fragment>
    )
  };

  if (!competition) {
    return <div>{t('NO_COMPETITION_SELECTED') as string}</div>;
  }

  return (
    <div>
      <Breadcrumb competition={competition} title="AVAILABLE_COURTS_TITLE" />
      {competition.move_match > 0 && competition.use_court && <p dangerouslySetInnerHTML={{__html: t('AVAILABLE_COURTS_TEXT')}}/>}
      {(competition.move_match === 0 || !competition.use_court) && <p dangerouslySetInnerHTML={{__html: t('AVAILABLE_COURTS_NOT_VISIBLE')}}/>}
      {competition.move_match > 0 && competition.use_court && renderCourts()}
      <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
    </div>
  );
}

export default AvailableCourts;