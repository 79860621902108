import React, {Component, Fragment} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {ThunkDispatch} from 'redux-thunk';
import {connect} from 'react-redux';
import moment from 'moment';
import {toast} from 'react-toastify';

import * as types from 'store/db/types';
import * as utils from 'utils';
import * as actions from '../../store/db/actions';
import * as api from '../../api';

import EditMoveMatch from 'components/Competition/EditMoveMatch';
import EditResult from 'components/Competition/EditResult';
import EditVaryingResult from 'components/Competition/EditVaryingResult';
import EditRemark from 'components/Competition/EditRemark';
import RegistrationInfo from 'components/Registration/RegistrationInfo';

import styles from './MatchesList.module.scss';

interface DispatchProps {
  updateMatch(match: types.Match): void;
}

interface Props extends DispatchProps, WithTranslation {
  competition: types.Competition;
  matches: types.Match[];
  type: 'Manager' | 'Player' | 'Unknown';
  onChange(): void;
}

interface State {
  showEditMoveMatch: boolean;
  showEditRemark: boolean;
  showEditResult: boolean;
  showRegistrationInfo: boolean;
  selectedMatchId: number | null;
  selectedRegistrationIds: number[];
  filter: string;
}

class MatchesList extends Component<Props, State> {

  state = {
    showEditMoveMatch: false,
    showEditRemark: false,
    showEditResult: false,
    showRegistrationInfo: false,
    selectedMatchId: null,
    selectedRegistrationIds: [],
    filter: ''
  };

  saveEditMoveMatch = async (match: types.Match, result: number) => {
    const {t, competition} = this.props;

    // Plan match
    try {
      if (!match.date_time) {
        await api.post(`matches/move/${competition.id}`, {
          unplanned_match_id: match.id,
          to_match_id: result
        });
      }
      // Move match to unplanned
      else if (result === -1) {
        await api.post(`matches/move/${competition.id}`, {
          from_match_id: match.id,
        });
      }
      // Move match
      else {
        await api.post(`matches/move/${competition.id}`, {
          from_match_id: match.id,
          to_match_id: result,
        });
      }

      toast(t('SAVE_SUCCESS') as string, {type: toast.TYPE.SUCCESS});
    } catch (e) {
      toast(t('SAVE_ERROR') as string, {type: toast.TYPE.ERROR});
    }

    this.props.updateMatch(match);
    this.setState({showEditMoveMatch: false});
    this.props.onChange();
  };

  saveEditResult = (competitionId: number, match: types.Match, resultMatch: types.Match) => {
    const {t} = this.props;

    api.patch(`matches/${competitionId}/${match.id}`, { result: resultMatch.result ? resultMatch.result : null, registrations: resultMatch.registrations, planned: !!match.date_time })
        .then(() => toast(t('SAVE_SUCCESS') as string, {type: toast.TYPE.SUCCESS}))
        .catch(() => toast(t('SAVE_ERROR') as string, {type: toast.TYPE.ERROR}));

    this.props.updateMatch(resultMatch);
    this.setState({showEditResult: false});
  };

  saveEditRemark = async (competitionId: number, match: types.Match, remark: string, removeTime: boolean) => {
    const {t} = this.props;
    try {
      await api.patch(`matches/${competitionId}/${match.id}`, {remark: remark ? remark : null, planned: !!match.date_time});
      if (removeTime) {
        await api.post(`matches/move/${competitionId}`, {
          from_match_id: match.id
        });
      }
      toast(t('SAVE_SUCCESS') as string, {type: toast.TYPE.SUCCESS});
    } catch (e) {
      toast(t('SAVE_ERROR') as string, {type: toast.TYPE.ERROR});
    }

    match.remark = remark;
    if (removeTime) {
      match.date_time = '1970-01-01T00:00:00Z';
      match.court = '';
    }
    this.props.updateMatch(match);
    this.setState({showEditRemark: false});
  };

  canMoveMatch = () => {
    const {competition, type} = this.props;

    return type === 'Manager' || competition.move_match === 2;
  };

  showRegistrationInfo = (registrationIds: number[]) => {
    if (registrationIds.length === 0) {
      return;
    }
    this.setState({showRegistrationInfo: true, selectedRegistrationIds: registrationIds});
  };

  render() {
    const { t, type } = this.props;
    const {selectedRegistrationIds} = this.state;
    return (
        <div>
          {type !== 'Player' && <div className={styles.filter}><input
              type="search"
              placeholder={t('SEARCH_PLAYER')}
              onChange={(event) => this.setState({filter: event.target.value})} /></div>}
          {this.renderMatchesUnplanned()}
          {this.renderMatches()}
          {this.renderEditPopups()}
          {this.state.showRegistrationInfo && <RegistrationInfo registrationIds={selectedRegistrationIds} onClose={() => this.setState({showRegistrationInfo: false})} />}
        </div>
    );
  }

  renderMatchesUnplanned = () => {
    const { t, type, matches } = this.props;
    const { filter } = this.state;

    let listMatches = matches.filter(match => !match.date_time);
    if (filter) {
      listMatches = listMatches.filter(match => utils.isIncluded(t, match, filter.toLocaleLowerCase()))
    }
    return (
        <Fragment>
          <div className="table">
            <table>
              <tbody>
              <tr>
                <th>{t('PLAYERS') as string}</th>
                <th/>
                <th/>
                <th>{t('RESULT') as string}</th>
                <th className="hide1">{t('REMARK') as string}</th>
                <th/>
              </tr>
              <tr className="dateHeader">
                <td colSpan={6}>
                  <i>{t('UNPLANNED_MATCHES') as string}</i>
                </td>
              </tr>
              {listMatches
                  // .sort((a, b) => moment(a.date_time).unix() - moment(b.date_time).unix())
                  .map((match, idx) => {
                    const homeRegistrationIds = match.registrations.filter(registration => registration.home_away === 'H').map(registration => registration.registration_id);
                    const awayRegistrationIds = match.registrations.filter(registration => registration.home_away === 'A').map(registration => registration.registration_id);
                    return (<Fragment key={idx}>
                      <tr>
                        {type !== 'Unknown' && <td><button className={styles.button} onClick={() => this.showRegistrationInfo(homeRegistrationIds)}>
                            {utils.getRegistrationName(t, match, 'H')}
                          </button></td>}
                        {type === 'Unknown' && <td>{utils.getRegistrationName(t, match, 'H')}</td>}
                        <td>-</td>
                        {type !== 'Unknown' && <td><button className={styles.button} onClick={() => this.showRegistrationInfo(awayRegistrationIds)}>
                            {utils.getRegistrationName(t, match, 'A')}
                          </button></td>}
                        {type === 'Unknown' && <td>{utils.getRegistrationName(t, match, 'A')}</td>}
                        <td>{this.renderResult(match)}</td>
                        <td className="hide1">{this.renderRemark(match)}</td>
                        <td>{this.renderCalendar(match)}</td>
                      </tr>
                    </Fragment>)
                  })}
              </tbody>
            </table>
          </div>
        </Fragment>
    )
  };

  renderMatches = () => {
    const { t, type, matches, competition } = this.props;
    const { filter } = this.state;

    let listMatches = matches.filter(match => match.date_time);
    if (filter) {
      listMatches = listMatches.filter(match => utils.isIncluded(t, match, filter.toLocaleLowerCase()))
    }
    let currentDateTime = 0;
    const colspan = 5 + (competition.use_court ? 1 : 0) + (this.canMoveMatch() ? 1 : 0);
    return (
        <Fragment>
          <div className="table">
            <table>
              <tbody>
                <tr>
                  <th>{t('PLAYERS') as string}</th>
                  <th/>
                  <th/>
                  {competition.use_court && <th>{t('COURT') as string}</th>}
                  <th>{t('RESULT') as string}</th>
                  <th className="hide1">{t('REMARK') as string}</th>
                  {this.canMoveMatch() && <th />}
                </tr>
                {listMatches
                    .sort((a, b) => moment(a.date_time || '1970-01-01').unix() - moment(b.date_time || '1970-01-01').unix())
                    .map((match, idx) => {
                      let dateHeader = null;
                      if (match.date_time && moment(match.date_time).unix() !== currentDateTime) {
                        currentDateTime = moment(match.date_time).unix();
                        if (moment.unix(currentDateTime).year() === 1970) {
                          dateHeader = (<tr className="dateHeader"><td colSpan={colspan}>
                            <i>{t('NOT_PLANNED') as string}</i>
                          </td></tr>)
                        } else {
                          dateHeader = (<tr className="dateHeader">
                            <td colSpan={colspan}>
                              <i>{moment(match.date_time).format('DD-MM-YYYY')}&nbsp;
                                {moment(match.date_time).format('HH:mm')}</i>
                            </td>
                          </tr>)
                        }
                      }
                      const homeRegistrationIds = match.registrations.filter(registration => registration.home_away === 'H').map(registration => registration.registration_id);
                      const awayRegistrationIds = match.registrations.filter(registration => registration.home_away === 'A').map(registration => registration.registration_id);
                      return (<Fragment key={idx}>
                        {dateHeader}
                        <tr>
                          {type !== 'Unknown' && <td><button className={styles.button} onClick={() => this.showRegistrationInfo(homeRegistrationIds)}>
                            {utils.getRegistrationName(t, match, 'H')}
                          </button></td>}
                          {type === 'Unknown' && <td>{utils.getRegistrationName(t, match, 'H')}</td>}
                          <td>-</td>
                          {type !== 'Unknown' && <td><button className={styles.button} onClick={() => this.showRegistrationInfo(awayRegistrationIds)}>
                            {utils.getRegistrationName(t, match, 'A')}
                          </button></td>}
                          {type === 'Unknown' && <td>{utils.getRegistrationName(t, match, 'A')}</td>}
                          {competition.use_court && <td>{match.court}</td>}
                          <td>{this.renderResult(match)}</td>
                          <td className="hide1">{this.renderRemark(match)}</td>
                          {this.canMoveMatch() && <td>{this.renderCalendar(match)}</td>}
                        </tr>
                      </Fragment>)
                    })}
              </tbody>
            </table>
          </div>
        </Fragment>
    )
  };

  renderResult = (match: types.Match) => {
    const {t, type} = this.props;
    if (utils.getRegistrationName(t, match, 'H') && type === 'Manager') {
      return (<Fragment>
        {match.result}&nbsp;
        <i className="fa fa-pencil-alt" style={{float: 'right'}}
           onClick={() => this.setState({showEditResult: true, selectedMatchId: match.id})} />
      </Fragment>);
    } else if (type === 'Player' && !match.result) {
      return <i className="fa fa-pencil-alt" style={{float: 'right'}}
                onClick={() => this.setState({showEditResult: true, selectedMatchId: match.id})} />;
    }
    return <Fragment>{match.result}</Fragment>;
  };

  renderRemark = (match: types.Match) => {
    const {t, type} = this.props;
    if (utils.getRegistrationName(t, match, 'H') && type !== 'Unknown') {
      return (<Fragment>
        {match.remark}&nbsp;
        <i className="fa fa-pencil-alt" style={{float: 'right'}}
           onClick={() => this.setState({showEditRemark: true, selectedMatchId: match.id})} />
      </Fragment>);
    }
    return <Fragment>{match.remark}</Fragment>;
  };

  renderCalendar = (match: types.Match) => {
    const {type} = this.props;
    if (type !== 'Unknown' && !match.result) {
      return (<Fragment>
        <i className="fa fa-calendar-alt"
           onClick={() => this.setState({showEditMoveMatch: true, selectedMatchId: match.id})}/>
      </Fragment>);
    }
    return null;
  };

  renderEditPopups = () => {
    const {showEditMoveMatch, showEditResult, showEditRemark, selectedMatchId} = this.state;
    const {competition} = this.props;
    if (!showEditResult && !showEditRemark && !showEditMoveMatch) {
      return null;
    }
    const {t, matches} = this.props;
    const match = matches.find(item => item.id === selectedMatchId);
    if (!match) {
      toast(t('MATCH_NOT_FOUND') as string, { type: toast.TYPE.ERROR });
      return null;
    }
    if (showEditResult) {
      return this.renderEditResult(match, competition);
    }
    else if (showEditMoveMatch) {
      return this.renderEditMoveMatch(match, competition)
    }
    return this.renderEditRemark(match, competition);
  };

  renderEditMoveMatch = (match: types.Match, competition: types.Competition) => {
    return <EditMoveMatch
        competition={competition}
        match={match}
        onClose={() => this.setState({showEditMoveMatch: false})}
        onSave={(result) => this.saveEditMoveMatch(match, result)}
    />
  };

  renderEditResult = (match: types.Match, competition: types.Competition) => {
    if (types.isCompetitionTypeWithVaryingPartner(match.type || undefined)) {
      return <EditVaryingResult
        competition={competition}
        match={match}
        type={this.props.type}
        onClose={() => this.setState({showEditResult: false})}
        onSave={(result) => this.saveEditResult(competition.id, match, result)}
    />
    }
    return <EditResult
      competition={competition}
      match={match}
      type={this.props.type}
      onClose={() => this.setState({showEditResult: false})}
      onSave={(result) => this.saveEditResult(competition.id, match, result)}
    />
  };

  renderEditRemark = (match: types.Match, competition: types.Competition) => {
    return <EditRemark
        competition={competition}
        match={match}
        onClose={() => this.setState({showEditRemark: false})}
        onSave={(remark, removeTime) => this.saveEditRemark(competition.id, match, remark, removeTime)}
    />
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => ({
  updateMatch: (match: types.Match) => dispatch(actions.updateMatch(match))
});

export default withTranslation()(connect(null, mapDispatchToProps)(MatchesList));