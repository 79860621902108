import React from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';

import * as api from 'api';
import * as types from 'store/db/types';

import ErrorMessageRequired from 'components/Validation/ErrorMessageRequired';

interface Props {
  competitionType: types.CompetitionType;
  onSave(group: types.Group): void;
  onClose(): void;
}

interface Form {
  group: string;
}

const AddGroup = ({competitionType, onSave, onClose}: Props) => {
  const {t} = useTranslation();
  const {handleSubmit, errors, register} = useForm();

  const onSaveGroup = async (data: Form) => {
    const savedGroup: types.Group = await api.add(t, `groups/${competitionType.competition_id}`, {
      competition_type_id: competitionType.id,
      name: data.group
    });
    if (savedGroup !== null) {
      onSave(savedGroup);
    }
  };

  return (
      <div className="popup">
        <div className="header">{t('ADD_GROUP_TITLE') as string}</div>
        <div className="content">
          <p dangerouslySetInnerHTML={{__html: t('ADD_GROUP_TEXT') as string}} />
          <form onSubmit={handleSubmit(onSaveGroup)}>
            <input name="group" ref={register({required: true})} placeholder={t('GROUP_NAME') as string} />
            <ErrorMessageRequired errors={errors.group} />
            <input type="submit" value={'' + t('SAVE') as string} />
            <input type="button" value={'' + t('CANCEL') as string} className="lastButton second" onClick={onClose} />
          </form>
        </div>
      </div>
  );
}

export default AddGroup;