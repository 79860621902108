import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import DatePicker from '../DatePicker';

import * as types from '../../store/db/types';

interface Props {
  competition: types.Competition;
  matches: types.Match[];
  onSave(dateTime: string, courts: string[]): void;
  onClose(): void;
}

const AddCourts = ({competition, matches, onSave, onClose}: Props) => {
  const {t} = useTranslation();

  const [date, setDate] = useState<string>();
  const [time, setTime] = useState<string>();
  const [courts, setCourts] = useState<string[]>(['']);
  const [errMsg, setErrMsg] = useState<string>();

  useEffect(() => {
    let msg: string | undefined = undefined;
    if (date && time && courts.find(court => court)) {
      for (const court of courts) {
        if (matches.find(match => match.date_time === `${date} ${time}:00` && match.court === court)) {
          msg = t('COURT_EXISTS', {date_time: `${date} ${time}`, court: court});
          break;
        }
      }
    }
    setErrMsg(msg);
  }, [date, time, courts, matches, t]);

  const updateCourt = (value: string, idx: number) => {
      const availableCourts = [...courts];
      availableCourts[idx] = value;
      if (availableCourts.length === idx + 1) {
        availableCourts.push('');
      }
      setCourts(availableCourts);
  }

  return (
      <div className="popup">
        <div className="header">{t('ADD_COURTS_TITLE') as string}</div>
        <div className="content">
          <label style={{color: 'red'}}>{errMsg}</label>
          <p>{t('ADD_COURTS_TEXT') as string}</p>
          <DatePicker
              placeholder={t('DATE') as string}
              onChange={(date) => date ? setDate(moment(date).format('YYYY-MM-DD')) : setDate(undefined)}
          />
          <input type="time" onChange={e => setTime(e.target.value)}/>
          {courts.map((court: string, idx: number) => <input key={idx} type="text" placeholder={t("COURT") as string}
                                                    onChange={e => updateCourt(e.target.value, idx)}/>)}
          <input
              type="button"
              value={'' + t('SAVE')}
              className="button"
              onClick={() => onSave(`${date} ${time}`, courts)}
              disabled={errMsg !== undefined || !date || !time || courts.length === 0 || !courts.find(court => court)}
          />
          <input
              type="button" value={'' + t('CANCEL') as string}
              className="lastButton second"
              onClick={onClose}
          />
        </div>
      </div>
  );
}

export default AddCourts;